import { OrderedSet, Map } from 'immutable';

import { getCursor } from './state';
import { INITIAL_STATE } from './constants';

const INITIAL_CUSROR_STORE_STATE = Map();

function setLastUpdate(state) {
  return state.set('lastUpdate', new Date().toISOString());
}

function prependResults(state, results) {
  const newIDs = OrderedSet(results.map(result => result.id));
  const currentIDs = state.get('results');

  state = setLastUpdate(state);

  return state.set('results', newIDs.union(currentIDs));
}

function appendResults(state, results) {
  const newIDs = results.map(result => result.id);

  state = setLastUpdate(state);

  return state.update('results', results => results.union(newIDs));
}

function setNext(state, url) {
  return state.set('next', url);
}

function setPrevious(state, url) {
  return state.set('previous', url);
}

function cursorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CURSOR/SET_NEXT':
      return setNext(state, action.url);
    case 'CURSOR/SET_PREVIOUS':
      return setPrevious(state, action.url);
    case 'CURSOR/PREPEND_PAGE':
      return prependResults(state, action.results);
    case 'CURSOR/APPEND_PAGE':
      return appendResults(state, action.results);
    case 'CURSOR/RESET':
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default function reducer(state = INITIAL_CUSROR_STORE_STATE, action) {
  const { cursorName, ...cursorAction } = action;
  let cursorState;

  if (!cursorName) return state;

  cursorAction.type = action.type.replace(`${cursorName}/`, '');
  cursorState = getCursor(state, cursorName);
  cursorState = cursorReducer(cursorState, cursorAction);

  return state.set(cursorName, cursorState);
}
