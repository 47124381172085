import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './AppStoreSelector.module.scss';

export default class AppStoreSelector extends PureComponent {
  static defaultProps = {
    playStore: true,
    appStore: true
  };

  static propTypes = {
    playStore: PropTypes.bool.isRequired,
    appStore: PropTypes.bool.isRequired
  };

  handleClick = ({ currentTarget: { href = '' } }) => {
    const isGoogle = href.includes('google');

    window.gtag('event', isGoogle ? 'Google Play' : 'Apple App Store', {
      event_category: 'App Store Selection',
      transport_type: 'beacon'
    });
  };

  render() {
    const { playStore, appStore } = this.props;

    return (
      <div className={styles.container}>
        {playStore && (
          <a
            className={styles.button}
            href="/retirement/"
            onClick={this.handleClick}
          >
            <img
              src={require('./play_store.svg')}
              alt="Get it on Google Play"
            />
          </a>
        )}
        {appStore && (
          <a
            onClick={this.handleClick}
            href="/retirement/"
            className={styles.button}
            title={'Download on the App Store'}
          >
            <img
              src={require('./app_store.svg')}
              alt="Download on the App Store"
            />
          </a>
        )}
      </div>
    );
  }
}
