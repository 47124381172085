import { APIClient as HHGAPIClient } from '@thomasw/hhgapiclient';

export function setToken(newToken) {
  APIClient.token = newToken;
}

export const APIClient = new HHGAPIClient({
  baseURL: 'https://api.staging.hiphipgo.net/api/'
});

export default APIClient;
