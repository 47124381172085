import React from 'react';
import { Helmet } from 'react-helmet-async';

import FullHeightSection from '../../components/FullHeightSection';
import Screen from '../../components/Screen';
import { Link } from 'react-router-dom';

import styles from './FourOhFour.module.scss';

export default function FourOhFour() {
  return (
    <Screen>
      <Helmet>
        <title>404: Page not found</title>
        <meta name="description" content="This page could not be found." />
        <meta name="keywords" content="404, not found" />
      </Helmet>
      <FullHeightSection className={styles.section}>
        <div>
          <h1>404 Page Not Found.</h1>
          <p>How&rsquo;d we end up in a place like this?</p>
          <p>
            C&rsquo;mon, let&rsquo;s head <Link to="/">home</Link>.
          </p>
        </div>
      </FullHeightSection>
    </Screen>
  );
}
