import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import FullHeightSection from '../components/FullHeightSection';
import Screen from '../components/Screen';

import styles from './Thanks.module.scss';

export default function Thanks() {
  return (
    <Screen>
      <Helmet>
        <title>HipHipGo: Thanks!</title>
        <meta
          name="description"
          content="Thanks! Your hiphipgo registration confirmation is on its way."
        />
      </Helmet>
      <FullHeightSection className={styles.section}>
        <div>
          <h1>Thanks for signing up!</h1>
          <p>
            We&rsquo;ve sent you an e-mail to confirm your registration. If you
            open it up and click the link, you&rsquo;ll be good to go.
          </p>
          <p>
            <Link to="/">Home</Link>
          </p>
        </div>
      </FullHeightSection>
    </Screen>
  );
}
