import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import { PlaceCard } from '../../map/components/PlaceCard';

import styles from './MapDemo.module.scss';

export default class MapDemo extends PureComponent {
  getAosProps = (offset = 0) => {
    return {
      'data-aos': 'fade-up',
      'data-aos-delay': offset * 200 || 0,
      'data-aos-anchor': `.${this.props.anchor}`
    };
  };

  render() {
    const grocery = fromJS({
      data: {
        name: 'Green Green Grocery',
        price_level: 2,
        rating: 5
      }
    });
    const sweets = fromJS({
      data: {
        name: 'Sweets & Treats',
        price_level: 4,
        rating: 3.25
      }
    });
    const grill = fromJS({
      data: {
        name: "Neutral Newt's Grill",
        price_level: 3,
        rating: 3.75
      }
    });

    return (
      <div className={styles.container} aria-hidden>
        <div className={styles.placeStack}>
          <PlaceCard
            id="sweets"
            place={sweets}
            impact={-1}
            {...this.getAosProps()}
          />
          <PlaceCard
            id="grill"
            place={grill}
            impact={0}
            {...this.getAosProps(1)}
          />
          <PlaceCard
            id="grocery"
            place={grocery}
            impact={1}
            {...this.getAosProps(2)}
          />
        </div>
      </div>
    );
  }
}
