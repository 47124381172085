import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './DeviceFrame.module.scss';

export default class DeviceFrame extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    shake: PropTypes.bool.isRequired,
    className: PropTypes.any,
    innerClassName: PropTypes.any
  };

  static defaultProps = {
    shake: false
  };

  render() {
    const {
      children,
      className,
      innerClassName,
      shake,
      ...divProps
    } = this.props;

    return (
      <div
        className={cx(styles.container, className, { [styles.shake]: shake })}
        aria-hidden
        {...divProps}
      >
        <div className={cx(styles.content, innerClassName)}>{children}</div>
      </div>
    );
  }
}
