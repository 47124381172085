import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { neutral, positive, negative } from './BackgroundFader';

import DeviceFrame from './DeviceFrame';

export default class TimelineDemo extends PureComponent {
  static defaultProps = {
    onColorChange: () => {}
  };

  static propTypes = {
    onColorChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    innerClassName: PropTypes.string
  };

  state = {
    color: neutral,
    loaded: false,
    visible: false
  };

  _setVideo = ref => (this.video = ref);

  componentDidMount() {
    // We can't specify disableRemotePlayback directly on the video tag
    // because RN doesn't yet reocgnize this attribute.
    this.video.setAttribute('disableRemotePlayback', true);

    window.document.addEventListener('aos:in:timelineDemo', this.setVisible);
  }

  componentWillUnmount() {
    window.document.removeEventListener('aos:in:timelineDemo', this.setVisible);
  }

  handleTimeUpdate = () => {
    const currentTime = this.video.currentTime;

    if (currentTime <= 3.26) return this.handleColorChange(neutral);
    if (currentTime <= 17.365) return this.handleColorChange(positive);
    if (currentTime <= 24.229) return this.handleColorChange(negative);

    return this.handleColorChange(neutral);
  };

  handleColorChange = async color => {
    if (color === this.state.color) return;

    this.setState({ color });
    this.props.onColorChange(color);
  };

  // We enable autoplay on the video but then pause it as soon as it starts.
  // Mobile Safari refuses to render the video element if both autoPlay and
  // controls are disabled, so we trick it to allow us to delay playback as
  // defined this.start()
  handleCanPlay = () => this.video.pause();

  setLoaded = () => this.setState({ loaded: true }, this.start);

  setVisible = () => this.setState({ visible: true }, this.start);

  start = () => {
    const { loaded, visible } = this.state;

    loaded && visible && this.video.play();
  };

  render() {
    return (
      <DeviceFrame
        className={this.props.className}
        innerClassName={this.props.innerClassName}
        data-aos="fade-up"
        data-aos-delay="1000"
        data-aos-duration="1500"
        data-aos-once
        data-aos-mirror={false}
        data-aos-id="timelineDemo"
      >
        <video
          aria-hidden
          autoPlay
          controls={false}
          loop
          muted
          onCanPlay={this.handleCanPlay}
          onCanPlayThrough={this.setLoaded}
          onTimeUpdate={this.handleTimeUpdate}
          playsInline
          preload="auto"
          onEnded={this.finished}
          ref={this._setVideo}
        >
          <source
            src={require('../video/timeline-small.mp4')}
            type="video/mp4"
          />
        </video>
      </DeviceFrame>
    );
  }
}
