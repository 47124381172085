import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './BreadCrumb.module.scss';

export default class BreadCrumb extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    darkMode: PropTypes.bool
  };
  static defaultProps = {
    text: 'Read more',
    darkMode: false
  };

  render() {
    const { target, darkMode } = this.props;
    const containerClasses = cx(styles.container, {
      [styles.darken]: darkMode
    });

    return (
      <div aria-hidden className={containerClasses}>
        <a href={target}>
          <i className="fa fa-chevron-circle-down fa-fw" title="{text}"></i>
        </a>
      </div>
    );
  }
}
