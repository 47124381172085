import React from 'react';
import { render } from 'react-dom';
import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'font-awesome/css/font-awesome.css';

import { makeStore } from './store';
import App from './App';

AOS.init();
new SmoothScroll('a[href*="#"]');

const store = makeStore();
const root = document.getElementById('app');

// TODO: We should be calling hydrate here if root has ChildNodes. However,
// hydration requires identical rendering between client side and server side
// otherwise subtle bugs are introduced (missing classes).
root && root.hasChildNodes()
  ? render(<App store={store} />, root)
  : render(<App store={store} />, root);
