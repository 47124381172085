import { monitorThunk } from 'redux-thunk-monitor';

import { getCursor } from './state';

export function setNext(cursorName, url) {
  return {
    type: `CURSOR/${cursorName}/SET_NEXT`,
    cursorName,
    url
  };
}

export function setPrevious(cursorName, url) {
  return {
    type: `CURSOR/${cursorName}/SET_PREVIOUS`,
    cursorName,
    url
  };
}

export function prependResults(cursorName, page) {
  return {
    type: `CURSOR/${cursorName}/PREPEND_PAGE`,
    cursorName,
    results: page.results
  };
}

export function appendResults(cursorName, page) {
  return {
    type: `CURSOR/${cursorName}/APPEND_PAGE`,
    cursorName,
    results: page.results
  };
}

export function storePage(cursorName, page) {
  return {
    type: `CURSOR/${cursorName}/STORE_PAGE`,
    cursorName,
    results: page.results
  };
}

export function reset(cursorName) {
  return {
    type: `CURSOR/${cursorName}/RESET`,
    cursorName
  };
}

export function resetAndGetItems(cursorName, curse, pageAction) {
  return dispatch => {
    dispatch(reset(cursorName));
    return dispatch(getOlderItems(cursorName, curse, pageAction));
  };
}

export function getItems(cursorName, curse, pageAction) {
  return (dispatch, getState) => {
    const state = getState();
    const cursorState = getCursor(state, cursorName);
    const previousUrl = cursorState.get('previous');

    // We've previously loaded items so we can abort this action and
    // ascend the cursor instead
    if (previousUrl) {
      return dispatch(getNewerItems(cursorName, curse, pageAction));
    }

    return dispatch(getOlderItems(cursorName, curse, pageAction));
  };
}

export function getNewerItems(cursorName, curse, pageAction) {
  async function getNewerItemsAsync(dispatch, getState) {
    const cursorState = getCursor(getState(), cursorName);
    const previousUrl = cursorState.get('previous');

    const handlePage = page => {
      page.previous && dispatch(setPrevious(cursorName, page.previous));
      dispatch(storePage(cursorName, page));
      dispatch(pageAction(page));
      dispatch(prependResults(cursorName, page));

      return page;
    };

    return await curse(
      { cursor: previousUrl, direction: 'reverse' },
      handlePage
    );
  }

  monitorThunk(getNewerItemsAsync, `cursor-${cursorName}`);

  return getNewerItemsAsync;
}

export function getOlderItems(cursorName, curse, pageAction) {
  async function getOlderItemsAsync(dispatch, getState) {
    const cursorState = getCursor(getState(), cursorName);
    const nextUrl = cursorState.get('next');

    const handlePage = page => {
      const cursorState = getCursor(getState(), cursorName);
      const previousUrl = cursorState.get('previous');

      !previousUrl &&
        page.previous &&
        dispatch(setPrevious(cursorName, page.previous));
      page.next && dispatch(setNext(cursorName, page.next));
      dispatch(storePage(cursorName, page));
      dispatch(pageAction(page));
      dispatch(appendResults(cursorName, page));

      return page;
    };

    return await curse({ cursor: nextUrl }, handlePage);
  }

  monitorThunk(getOlderItemsAsync, `cursor-${cursorName}`);

  return getOlderItemsAsync;
}
