import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import ScrollManager from './ScrollManager';
import TrailingSlashRedirect from './TrailingSlashRedirect';
import FourOhFour from '../views/FourOhFour';

export default class AppRouter extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  };

  render() {
    const { errorMap, ...routerProps } = this.props;

    return (
      <Router {...routerProps}>
        <Route exact strict path="/:url*" component={TrailingSlashRedirect} />
        <Switch>
          {this.props.children}
          <Route component={FourOhFour} />
        </Switch>
        <ScrollManager />
      </Router>
    );
  }
}
