import React, { PureComponent } from 'react';
import cx from 'classnames';

import BreadCrumb from '../components/BreadCrumb';
import FullHeightSection from '../components/FullHeightSection';
import Screen from '../components/Screen';

import AppStoreSelector from './components/AppStoreSelector';
import BackgroundFader from './components/BackgroundFader';
import TimelineDemo from './components/TimelineDemo';
import WeightGraphDemo from './components/WeightGraphDemo';
import FactorDemo from './components/FactorDemo';
import ProximityDemo from './components/ProximityDemo';
import MapDemo from './components/MapDemo';
import CoffeeVideo from './components/CoffeeVideo';

import styles from './Home.module.scss';

export default class Home extends PureComponent {
  state = { color: BackgroundFader.neutral };

  changeColor = color => this.setState({ color });

  getAOSProps(direction, anchor, delayMultiplier) {
    return {
      'data-aos': direction ? `fade-${direction}` : null,
      'data-aos-delay': delayMultiplier ? delayMultiplier * 100 : undefined,
      'data-aos-anchor': `.${anchor}`
    };
  }

  render() {
    const { color } = this.state;
    return (
      <Screen>
        <FullHeightSection className={styles.primary}>
          <BackgroundFader
            color={color}
            baseOverlayClassName={styles.bgOverlay}
          />

          <div className={styles.mainContent}>
            <div className={styles.copy}>
              <p><a href="/retirement/">HipHipGo has retired! &raquo;</a></p>
              <h1>
                Your Weight
                <em> Before You Weigh It</em>
              </h1>
              <p>
                Forecasts based on where&nbsp;you&nbsp;go,
                how&nbsp;you&nbsp;get&nbsp;there,
                and&nbsp;what&nbsp;you&nbsp;spend.
              </p>
              <AppStoreSelector />
            </div>
            <TimelineDemo
              className={styles.demo}
              innerClassName={styles.timelineDemo}
              onColorChange={this.changeColor}
            />
          </div>

          <BreadCrumb target="#learn-more" />
        </FullHeightSection>
        <section
          id="learn-more"
          className={cx(styles.contentSection, styles.learn)}
        >
          <div>
            <h1 data-aos="fade-up">Make your data work&nbsp;for&nbsp;you</h1>
            <p data-aos="fade-up">
              Every bit of data recorded about us offers a window into the
              health related decisions we make.
            </p>
            <p data-aos="fade-up">
              Here&rsquo;s how we
              <strong> make your data work&nbsp;for&nbsp;you</strong>:
            </p>
          </div>
          <div className={styles.gridSection}>
            <div className={styles.callout}>
              <div className={styles.bg}>
                <CoffeeVideo />
              </div>
              <FactorDemo />
            </div>
            <div className={cx([styles.copy, 'autoTrackCopy'])}>
              <h2 {...this.getAOSProps('left', 'autoTrackCopy')}>
                Automatic Tracking
              </h2>
              <h3 {...this.getAOSProps('left', 'autoTrackCopy', 1)}>
                Do what you do
                <br /> without logging it.
              </h3>
              <p {...this.getAOSProps('left', 'autoTrackCopy', 2)}>
                HipHipGo can automatically monitor how active you are, where you
                go, and how you&rsquo;re spending.
              </p>
              <p {...this.getAOSProps('left', 'autoTrackCopy', 2)}>
                <strong>Focus on living</strong> your life, not counting
                calories or logging everything you do.
              </p>
            </div>
          </div>
          <div className={styles.gridSection}>
            <div className={styles.callout}>
              <WeightGraphDemo />
            </div>
            <div className={cx([styles.copy, 'forecastCopy'])}>
              <h2 {...this.getAOSProps('right', 'forecastCopy')}>
                Weight Forecasts
              </h2>
              <h3 {...this.getAOSProps('right', 'forecastCopy', 1)}>
                Know your weight
                <br />
                before you weigh it.
              </h3>
              <p {...this.getAOSProps('right', 'forecastCopy', 2)}>
                HipHipGo uses your data to forecast how your weight will change
                in the coming days.
              </p>
              <p {...this.getAOSProps('right', 'forecastCopy', 2)}>
                With your weight graph, you&rsquo;ll always know if{' '}
                <strong>you&rsquo;re on track</strong> to achieve your health
                goals.
              </p>
            </div>
          </div>
          <div className={styles.gridSection}>
            <div className={styles.callout}>
              <div className={styles.bg} aria-hidden>
                <img src={require('./map.jpg')} alt="" />
              </div>
              <MapDemo anchor={'placeCopy'} />
            </div>
            <div className={cx([styles.copy, 'placeCopy'])}>
              <h2 {...this.getAOSProps('left', 'placeCopy')}>
                Things to do
                <br /> Food to eat
              </h2>
              <h3 {...this.getAOSProps('left', 'placeCopy', 1)}>
                Find places compatible with your goals.
              </h3>
              <p {...this.getAOSProps('left', 'placeCopy', 2)}>
                HipHipGo knows when customers at restaurants and local area
                businesses gain or lose weight.
              </p>
              <p {...this.getAOSProps('left', 'placeCopy', 2)}>
                <strong>Find places</strong> that mesh with your goals and avoid
                ones that don&rsquo;t.
              </p>
            </div>
          </div>
          <div className={styles.gridSection}>
            <div className={styles.callout}>
              <ProximityDemo activateAnchor={'alertCopy'} />
            </div>
            <div className={cx([styles.copy, 'alertCopy'])}>
              <h2 {...this.getAOSProps('right', 'alertCopy')}>
                Relevant Alerts
                <br />
              </h2>
              <h3 {...this.getAOSProps('right', 'alertCopy', 1)}>
                Only when you need them
              </h3>
              <p {...this.getAOSProps('right', 'alertCopy', 2)}>
                HipHipGo will alert you when you stop somewhere that&rsquo;s
                contrary to your weight goals. We&rsquo;ll also alert you when
                your forecast changes direction.
              </p>
              <p {...this.getAOSProps('right', 'alertCopy', 2)}>
                <strong>We&rsquo;re obsessive about your metrics </strong>
                so that you don&rsquo;t have to be.
              </p>
            </div>
          </div>
          <BreadCrumb target="#integrations" darkMode />
        </section>
        <section
          id="integrations"
          className={cx(styles.contentSection, styles.integrations)}
        >
          <BackgroundFader
            color={color}
            baseOverlayClassName={styles.integrationsOverlay}
          />

          <div className={cx(styles.gridSection, styles.integrationsGrid)}>
            <div className={styles.callout}>
              <ul>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="150"
                >
                  <img src={require('./runkeeper.svg')} alt="Runkeeper" />
                </li>
                <li data-aos="flip-up" data-aos-anchor="#integrations">
                  <img src={require('./strava.svg')} alt="Strava" />
                </li>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="200"
                >
                  <img src={require('./googlefit.svg')} alt="Google Fit" />
                </li>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="50"
                >
                  <img src={require('./wellsfargo.svg')} alt="Wells Fargo" />
                </li>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="250"
                >
                  <img src={require('./boa.svg')} alt="Bank of America" />
                </li>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="100"
                >
                  <img src={require('./chase.svg')} alt="Chase" />
                </li>
                <li
                  data-aos="flip-up"
                  data-aos-anchor="#integrations"
                  data-aos-delay="300"
                >
                  <img src={require('./nike.svg')} alt="NikePlus" />
                </li>
              </ul>
            </div>
            <div className={cx([styles.copy, 'integrationsCopy'])}>
              <h1>Integrations</h1>
              <p>
                If it stores data about you, chances are HipHipGo can make that
                data work for you.
              </p>
              <p data-aos="fade-up">+ A few thousand more.</p>
            </div>
          </div>

          <BreadCrumb target="#get-started" darkMode />
        </section>
        <section
          id="get-started"
          className={cx(styles.contentSection, styles.getStarted)}
        >
          <h1>Get Started Today</h1>
          <p>
            You&rsquo;re almost there. Join the beta program by installing
            HipHipGo on your Android or iOS device.
          </p>
          <div className={styles.appSelector}>
            <AppStoreSelector />
          </div>
        </section>
      </Screen>
    );
  }
}
