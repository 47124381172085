import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './PageHeader.module.scss';

export default class Navbar extends PureComponent {
  state = {
    menuHover: false,
    menuExpanded: false
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    const menuHover = scrollPosition > 50;

    if (menuHover !== this.state.menuHover) {
      this.setState({ menuHover: menuHover });
    }
  };

  handleExpandNav = () => {
    this.setState({ menuExpanded: !this.state.menuExpanded });
  };

  render() {
    const inApp = window.location.search.match(/\bapp=1\b/);
    const navStyles = cx(styles.appNav, {
      [styles.active]: this.state.menuHover,
      [styles.appNavClosed]: !this.state.menuExpanded
    });
    const headerStyles = cx({ [styles.hide]: inApp });

    return (
      <header className={headerStyles}>
        <nav className={navStyles}>
          <h1 className={styles.brand}>
            <Link to={{ pathname: '/', hash: '#app' }}>
              <img src={require('./hiphipgo.png')} alt="hiphipgo" />
            </Link>
          </h1>
          <button className={styles.toggle} onClick={this.handleExpandNav}>
            <span className="fa fa-bars" aria-hidden="true"></span>
          </button>
          <ul className={styles.menu}>
            <li>
              <Link to={{ pathname: '/', hash: '#app' }}>Home</Link>
            </li>
            <li>
              <Link to={{ pathname: '/', hash: '#learn-more' }}>
                Learn More
              </Link>
            </li>
            <li>
              <Link to={{ pathname: '/', hash: '#get-started' }}>
                Get Started
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}
