import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';

import animationData from './loader.json';

export default class LoadingAnimation extends PureComponent {
  render() {
    return (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData
        }}
        {...this.props}
      />
    );
  }
}
