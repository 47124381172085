import { fromJS, List } from 'immutable';
import { isLoading } from 'redux-thunk-monitor';

import { PlaceFeature } from './geometry';
import { getCursor } from '../cursors/state';

export function getRegion(state, defaultValue) {
  const region = state.getIn(['map', 'region'], defaultValue);

  return region || defaultValue;
}

export function getSearchRegion(state, defaultValue) {
  const region = state.getIn(['map', 'searchRegion'], defaultValue);

  return region || defaultValue;
}

export function getPlace(state, id, defaultValue) {
  return state.getIn(['map', 'places', id], defaultValue);
}

export function getPlaceImpact(state, id) {
  const place = getPlace(state, id);

  if (!place) return;

  const { p, mean } = place.get('annotations').toJS();
  const placeTypes = place.getIn(['data', 'types'], List());
  const primaryPlaceType = placeTypes.first();

  // TODO: Remove this. This is a temporary hack intended to simulate more
  // diverse results from the model.
  if (primaryPlaceType === 'bar' && !placeTypes.contains('restaurant')) {
    return -1;
  }

  if (p === null && mean === null) {
    return undefined;
  }

  if (p < 0.5 && mean > 0) {
    return -1;
  }

  if (p < 0.5 && mean < 0) {
    return 1;
  }

  return 0;
}

export function getSearchResults(state) {
  return getSearchCursor(state).get('results');
}

export function hasSearchResults(state) {
  return !getSearchCursor(state)
    .get('results')
    .isEmpty();
}

export function getSearchResultFeatures(state) {
  const places = getSearchResults(state);

  return places.map(id => fromJS(PlaceFeature(getPlace(state, id))));
}

export function hasMoreSearchResults(state) {
  return !!getCursor(state, 'map.search').get('next');
}

export function isSearchLoading(state) {
  return isLoading({ thunks: state.get('thunks') }, 'mapSearch');
}

export function getSearchCursor(state) {
  return getCursor(state, 'map.search');
}

export function getSearchQuery(state) {
  return state.getIn(['map', 'searchQuery']);
}
