export function BoundingBox(latLngBound, center) {
  const { north, east, south, west } = latLngBound.toJSON();
  const lat = center.lat();
  const lng = center.lng();
  const latD = Number(north) - Number(south);
  let lngD = Number(east) - Number(west);

  if (lngD < 0) lngD = lngD + 360;

  return [lng - lngD, lat - latD, lng + lngD, lat + latD];
}

export function PlaceFeature(place) {
  return {
    type: 'Feature',
    geometry: place.get('location').toJS(),
    properties: { id: place.get('id') }
  };
}
