import { combineReducers } from 'redux-immutable';

import { thunkMonitorReducer } from 'redux-thunk-monitor';
import cursorReducer from './cursors/reducer';
import mapReducer from './map/reducer';

export const rootReducer = combineReducers({
  thunks: thunkMonitorReducer,
  cursors: cursorReducer,
  map: mapReducer
});

export default rootReducer;
