import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';

export default function NavChip(props) {
  const { children, hash, ...chipProps } = props;

  return (
    <a href={`#${hash}`}>
      <Chip label={children} clickable {...chipProps} />
    </a>
  );
}

NavChip.propTypes = {
  children: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired
};
