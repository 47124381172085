import React, { PureComponent } from 'react';

import LoadingAnimation from './LoadingAnimation';

const styles = {
  container: {
    height: '100vh',
    width: '100vw',
    background: '#E5E3DF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8
  }
};

export default class LoadingScreen extends PureComponent {
  render() {
    return (
      <div style={styles.container}>
        <LoadingAnimation width={'100px'} />
      </div>
    );
  }
}
