import React, { PureComponent } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import LoadingScreen from './LoadingScreen';

const mapStyle = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];

const GoogleMapsWrapper = withScriptjs(
  withGoogleMap(props => {
    const { onMapMounted, ...otherProps } = props;
    return (
      <GoogleMap {...otherProps} ref={c => onMapMounted && onMapMounted(c)} />
    );
  })
);

export default class Map extends PureComponent {
  static API_KEY = 'AIzaSyCc5r7Ve09Ma_lYbSlaI5OtbXD5lcaT0SY';

  render() {
    return (
      <GoogleMapsWrapper
        googleMapURL={
          `https://maps.googleapis.com/maps/api/js?key=${Map.API_KEY}` +
          `&v=3.exp&libraries=geometry,drawing,places`
        }
        loadingElement={<LoadingScreen />}
        containerElement={
          <div style={{ height: `100vh`, background: '#E5E3DF' }} />
        }
        mapElement={<div style={{ height: `100vh`, background: '#E5E3DF' }} />}
        defaultZoom={13}
        defaultCenter={{
          lat: 37.7577,
          lng: -122.4376
        }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyle
        }}
        {...this.props}
      />
    );
  }
}
