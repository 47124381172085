import React, { PureComponent } from 'react';

import styles from './FactorDemo.module.scss';

export default class FactorDemo extends PureComponent {
  _setVideo = ref => (this.video = ref);

  componentDidMount() {
    // We can't specify disableRemotePlayback directly on the video tag
    // because RN doesn't yet reocgnize this attribute.
    this.video.setAttribute('disableRemotePlayback', true);
  }

  render() {
    return (
      <div className={styles.container} aria-hidden>
        <video
          aria-hidden
          autoPlay
          className={styles.primary}
          controls={false}
          loop
          muted
          playsInline
          poster={require('../video/factor-poster.jpg')}
          preload="auto"
          ref={this._setVideo}
        >
          <source
            src={require('../video/factor-demo-small.mp4')}
            type="video/mp4"
          />
        </video>
      </div>
    );
  }
}
