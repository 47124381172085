import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';

import Screen from '../components/Screen';
import NavChip from './components/NavChip';

import styles from './Legal.module.scss';

export default function PrivacyPolicy() {
  const inApp = window.location.search.match(/\bapp=1\b/);
  const chipColor = inApp ? 'primary' : 'default';
  const headerStyles = cx(styles.header, { [styles.inApp]: inApp });

  return (
    <Screen>
      <Helmet>
        <title>HipHipGo: Terms of Service</title>
        <meta name="description" content="HipHipGo terms of service." />
        <meta
          name="keywords"
          content="hiphipgo, prviacy, terms, terms of service, legal, tos"
        />
      </Helmet>
      <section className={headerStyles}>
        <h1>Terms of Service</h1>
        <p>
          Thanks for using our products and services (&ldquo;Services&rdquo;).
          The Services are provided by HipHipGo, Inc. (&ldquo;HipHipGo&rdquo;)
        </p>
        <p>
          By using our Services, you are agreeing to these terms. Please read
          them carefully.
        </p>

        <div className={styles.toc}>
          <NavChip hash="users" color={chipColor}>
            Users
          </NavChip>
          <NavChip hash="visitors" color={chipColor}>
            Visitors
          </NavChip>
          <NavChip hash="payment" color={chipColor}>
            Fees and Payment
          </NavChip>
          <NavChip hash="your_own_risk" color={chipColor}>
            Use at Your Own Risk
          </NavChip>
          <NavChip hash="other_websites" color={chipColor}>
            Other Websites
          </NavChip>
          <NavChip hash="third_party" color={chipColor}>
            Third Party Services
          </NavChip>
          <NavChip hash="dmca" color={chipColor}>
            DMCA
          </NavChip>
          <NavChip hash="ip" color={chipColor}>
            Intellectual Property
          </NavChip>
          <NavChip hash="changes" color={chipColor}>
            Changes
          </NavChip>
          <NavChip hash="termination" color={chipColor}>
            Termination
          </NavChip>
          <NavChip hash="disclaimer" color={chipColor}>
            Disclaimer of Warranties
          </NavChip>
          <NavChip hash="jurisdiction" color={chipColor}>
            Jurisdiction
          </NavChip>
          <NavChip hash="arbitration" color={chipColor}>
            Arbitration
          </NavChip>
          <NavChip hash="liability" color={chipColor}>
            Limitation of Liability
          </NavChip>
          <NavChip hash="representation" color={chipColor}>
            General Representation and Warranty
          </NavChip>
          <NavChip hash="sanctions" color={chipColor}>
            US Economic Sanctions
          </NavChip>
          <NavChip hash="indemnification" color={chipColor}>
            Indemnification
          </NavChip>
          <NavChip hash="translation" color={chipColor}>
            Translation
          </NavChip>
          <NavChip hash="miscellaneous" color={chipColor}>
            Miscellaneous
          </NavChip>
        </div>

        <p>
          Effective <strong>March 10, 2019</strong>
        </p>
      </section>
      <div className={styles.doc}>
        <section className={styles.contentSection} id="terms">
          <p>
            The following terms and conditions (&ldquo;Terms&rdquo;) govern all
            use of the hiphipgo.com website and all content, services, devices,
            APIs, applications, programs, and products available at or through
            the website, including, but not limited to, the HipHipGo app for
            iOS, the HipHipGo app for Android, (taken together, our
            &ldquo;Services&rdquo;). Our Services are offered subject to your
            acceptance without modification of all of the terms and conditions
            contained herein and all other operating rules, policies (including,
            without limitation, HipHipGo&rsquo;s{' '}
            <a href="/privacy/">Privacy Policy</a>) and procedures that may be
            published from time to time by HipHipGo (collectively, the
            &ldquo;Agreement&rdquo;). You agree that we may automatically
            upgrade our Services, and these Terms will apply to any upgrades.
          </p>
          <p>
            We refer to HipHipGo, Inc. as &ldquo;HipHipGo&rdquo; or
            &ldquo;we&rdquo; throughout this agreement.
          </p>
          <p>
            Please read this Agreement carefully before accessing or using our
            Services. By accessing or using any part of our Services, you agree
            to become bound by the Terms of this Agreement. If you do not agree
            to all the Terms of this Agreement, then you may not access or use
            any of our Services. If these Terms are considered an offer by
            HipHipGo, acceptance is expressly limited to these Terms.
          </p>
          <p>
            Our Services are not directed to children. Access to and use of our
            Services is only for those over the age of 13 (or 16 in the European
            Union). If you are younger than this, you may not register for or
            use our Services. Any person who registers as a user or provides
            their personal information to our Services represents that they are
            13 years of age or older (16 years or older in the European Union).
          </p>
          <p>
            Use of our Services requires a HipHipGo account. You agree to
            provide us with complete and accurate information when you register
            for an account. You will be solely responsible and liable for any
            activity that occurs under your username. You are responsible for
            keeping your password secure.
          </p>
        </section>
        <section className={styles.contentSection} id="users">
          <h1>Responsibility of Users</h1>
          <p>
            <strong>Your HipHipGo Account.</strong> You are responsible for
            maintaining the security of your account, and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with the account. You must
            immediately notify HipHipGo of any unauthorized uses of your
            account, or any other breaches of security. HipHipGo will not be
            liable for any acts or omissions by you, including any damages of
            any kind incurred as a result of such acts or omissions.
          </p>
          <p>
            <strong>Responsibility of Contributors.</strong> If you add or log
            material to your account, or otherwise make (or allow any third
            party to make) material available (any such material,
            &ldquo;Content&rdquo;), you are entirely responsible for the content
            of, and any harm resulting from, that Content or your conduct. That
            is the case regardless of what form the Content takes, which
            includes, but is not limited to text, photo, video, audio, or code.
            By using HipHipGo, you represent and warrant that your Content and
            conduct do not violate these Terms. By submitting Content to
            HipHipGo for inclusion in your account, you grant HipHipGo a
            world-wide, royalty-free, and non-exclusive license to reproduce,
            modify, adapt, aggregate, and publish the Content. This license
            allows HipHipGo to make publicly-posted content available to third
            parties selected by HipHipGo so that these third parties can analyze
            and distribute (but not publicly display) your content through their
            services. If you delete Content, HipHipGo will use reasonable
            efforts to remove it from the service, but you acknowledge that
            caching or references to the Content may not be made immediately
            unavailable. Without limiting any of those representations or
            warranties, HipHipGo has the right (though not the obligation) to,
            in HipHipGo&rsquo;s sole discretion, (i) reclaim your username or
            remove your submitted content due to prolonged inactivity, (ii)
            refuse or remove any content that, in HipHipGo&rsquo;s reasonable
            opinion, violates any HipHipGo policy or is in any way harmful or
            objectionable, or (iii) terminate or deny access to and use of the
            Services to any individual or entity for any reason. HipHipGo will
            have no obligation to provide a refund of any amounts previously
            paid.
          </p>
        </section>
        <section className={styles.contentSection} id="visitors">
          <h1>Responsibility of Visitors</h1>
          <p>
            We have not reviewed, and cannot review, all of the material posted
            to our Services by users or anyone else (&ldquo;Site
            Materials&rdquo;), and are not responsible for any Site
            Materials&rsquo; content, use, or effects. We do not endorse any
            Site Materials or represent that Site Materials are accurate,
            useful, or non-harmful. We also disclaim any responsibility for any
            harm resulting from anyone&rsquo;s use or downloading of Site
            Materials. If you access or use any Site Materials, you are
            responsible for taking precautions as necessary to protect yourself
            and your computer systems from viruses, worms, Trojan horses, and
            other harmful or destructive content. Site Materials may be
            offensive, indecent, objectionable, or include technical
            inaccuracies, typographical mistakes, and other errors, or violate
            or infringe the privacy or publicity rights, intellectual property
            rights, (see &ldquo;
            <a href="#dmca">Copyright Infringement and DMCA Policy</a>&rdquo;
            below to submit copyright complaints) and other proprietary rights,
            of third parties.
          </p>
        </section>
        <section className={styles.contentSection} id="payment">
          <h1>Fees, Payment, and Renewal</h1>
          <p>
            Fees. Some of our Services are offered for a fee while other
            Services may be free with optional paid upgrades, such as a HipHipGo
            plan (&ldquo;Paid Services&rdquo;). By using a Paid Service, you
            agree to pay the specified fees. Depending on the Paid Service,
            there may be a one-time fee or recurring fees. For recurring fees,
            we&rsquo;ll bill or charge you for in regular intervals (such as
            monthly, annually, or biennially), on a pre-pay basis until you
            cancel, which you can do at any time by contacting the relevant
            support team.
          </p>
          <p>
            <strong>Taxes.</strong> To the extent permitted by law, or unless
            explicitly stated otherwise, all fees are exclusive of applicable
            federal, provincial, state, local or other governmental sales, goods
            and services, harmonized or other taxes, fees, or charges now in
            force or enacted in the future (&ldquo;Taxes&rdquo;). You are
            responsible for payment of all applicable Taxes relating to your use
            of our Services, your payments, or your purchases. If we are
            obligated to pay or collect Taxes on the Fees you&rsquo;ve paid or
            will pay, you are responsible for such Taxes, and we may collect
            payment for such Taxes.
          </p>
          <p>
            <strong>Payment.</strong> If your payment fails or Paid Services are
            otherwise not paid for on time, we may immediately cancel or revoke
            your access to the Paid Services. If you contact your bank or credit
            card company to decline or reverse the charge of fees for Paid
            Services, we may revoke your access to our Services in general.
          </p>
          <p>
            <strong>Automatic Renewal.</strong> To ensure uninterrupted service,
            recurring Paid Services are automatically renewed. This means that
            unless you cancel a Paid Service before the end of the applicable
            subscription period, it will automatically renew, and you authorize
            us to invoice you or use any payment mechanism we have on record for
            you to collect the then-applicable subscription fee (as well as any
            Taxes). Your Paid Services are renewed for the same interval of
            time. For example, if you purchase a HipHipGo annual plan, you will
            be charged each year.
          </p>
          <p>
            <strong>Refunds.</strong> While you may cancel a Paid Service at any
            time, refunds are issued in our sole discretion.
          </p>
          <p>
            <strong>Fee Changes.</strong> We may change our fees at any time.
            When applicable, we may give you advance notice of the fee changes.
            If you don&rsquo;t agree with the fee changes, you can cancel your
            Paid Service.
          </p>
        </section>
        <section className={styles.contentSection} id="your_own_risk">
          <h1>Use at Your Own Risk</h1>
          <p>
            The Services are not intended to diagnose, treat, cure, or prevent
            any disease. If you have a medical condition, consult your doctor
            before using the Services, engaging in exercise, or making dietary
            changes.
          </p>
          <p>
            We are not responsible for any health problems that may result from
            training programs, consultations, products, or events you learn
            about through the Service. If you engage in any exercise program you
            receive or learn about through the Service, you agree that you do so
            at your own risk and are voluntarily participating in these
            activities.
          </p>
          <p>
            We aim to publish useful and accurate information on the Service,
            but we make no endorsement, representation, or warranty of any kind
            about any HipHipGo Content, information, or services. The accuracy
            of the data collected and presented through the HipHipGo Service is
            not intended to match that of medical devices or scientific
            measurement devices.
          </p>
          <p>
            We are not responsible for the accuracy, reliability, availability,
            effectiveness, or correct use of information you receive through the
            HipHipGo Service. HipHipGo Content and the HipHipGo Service may
            change from time to time or vary by geographic location. Maps and
            GPS or navigation data, including data relating to your current
            location, may be unavailable, inaccurate, incomplete, or otherwise
            unreliable.
          </p>
        </section>
        <section className={styles.contentSection} id="other_websites">
          <h1>Content Posted on Other Websites</h1>
          <p>
            We have not reviewed, and cannot review, all of the material,
            including computer software, made available through the websites and
            webpages to which hiphipgo.com links, and that link to hiphipgo.com.
            HipHipGo does not have any control over those non-hiphipgo.com
            websites, and is not responsible for their contents or their use. By
            linking to a non-hiphipgo.com website, HipHipGo does not represent
            or imply that it endorses such website. You are responsible for
            taking precautions as necessary to protect yourself and your
            computer systems from viruses, worms, Trojan horses, and other
            harmful or destructive content. HipHipGo disclaims any
            responsibility for any harm resulting from your use of
            non-hiphipgo.com websites and webpages.
          </p>
        </section>
        <section className={styles.contentSection} id="third_party">
          <h1>Third Party Services</h1>
          <p>
            You may enable services, products, software (like integrations), or
            applications developed by a third party or yourself (&ldquo;Third
            Party Services&rdquo;) on your site.
          </p>
          <p>If you use any Third Party Services, you understand that:</p>
          <ul>
            <li>
              Third Party Services are not vetted, endorsed, or controlled by
              HipHipGo.
            </li>
            <li>
              Any use of a Third Party Service is at your own risk, and we shall
              not be responsible or liable to anyone for Third Party Services.
            </li>
            <li>
              Your use is solely between you and the respective third party
              (&ldquo;Third Party&rdquo;) and will be governed by the Third
              Party&rsquo;s terms and policies. It is your responsibility to
              review the Third Party&rsquo;s terms and policies before using a
              Third Party Service.
            </li>
            <li>
              Some Third Party Services may request or require access to your
              data. If you grant access, your data will handled in accordance
              with the Third Party&rsquo;s privacy policy and practices. We do
              not have control over how a Third Party Service may use your data.
              You should carefully review Third Party Services&rsquo; data
              collection, retention, and use policies and practices before
              enabling Third Party Services.
            </li>
            <li>
              Third Party Services may not work appropriately with your website,
              and we may not be able to provide support for issues caused by any
              Third Party Services.
            </li>
          </ul>
          <p>
            If you have questions or concerns about how a Third Party Service
            operates, or need support, please contact the Third Party directly.
          </p>
          <p>
            In rare cases, we may at our discretion, suspend, disable, or remove
            Third Party Services from your account or website.
          </p>
        </section>
        <section className={styles.contentSection} id="dmca">
          <h1>Copyright Infringement and DMCA Policy</h1>
          <p>
            If you believe that anything on or linked by hiphipgo.com or the
            Services infringes upon any copyright which you own or control, you
            may file a notification of such infringement with our Designated
            Agent as set forth below.
          </p>
          <p>
            Full Address of Designated Agent to Which Notification Should be
            Sent:
          </p>
          <address>
            DMCA Agent
            <br />
            ATTN: Legal Department (DMCA)
            <br />
            info@hiphipgo.com
          </address>
          <p>
            To be effective, the notification must be a written communication
            that includes the following:
          </p>
          <ul>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed;
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site;
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material;
            </li>
            <li>
              Information reasonably sufficient to permit us to contact the
              complaining party, such as an address, telephone number and, if
              available, an electronic mail address at which the complaining
              party may be contacted;
            </li>
            <li>
              A statement that the complaining party has a good-faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent or the law; and
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed.
            </li>
          </ul>
          <p>
            We may give notice of a claim of copyright infringement to our users
            by means of a general notice through the service, electronic mail to
            a user&rsquo;s email address in our records, or by written
            communication sent by first-class mail to a user&rsquo;s address in
            our records.
          </p>
          <p>
            In accordance with the Digital Millennium Copyright Act (DMCA) and
            other applicable law, HipHipGo has adopted a policy of terminating,
            in appropriate circumstances and at the sole discretion of HipHipGo,
            subscribers or account holders who are deemed to be repeat
            infringers. HipHipGo may also at its sole discretion limit access to
            the Site and/or terminate the accounts of any users who infringe any
            intellectual property rights of others, whether or not there is any
            repeat infringement.
          </p>
        </section>
        <section className={styles.contentSection} id="ip">
          <h1>Intellectual Property</h1>
          <p>
            This Agreement does not transfer from HipHipGo to you any HipHipGo
            or third party intellectual property, and all right, title, and
            interest in and to such property will remain (as between the
            parties) solely with HipHipGo. HipHipGo, the HipHipGo logo, and all
            other trademarks, service marks, graphics and logos used in
            connection with hiphipgo.com or our Services, are trademarks or
            registered trademarks of HipHipGo or HipHipGo&rsquo;s licensors.
            Other trademarks, service marks, graphics and logos used in
            connection with our Services may be the trademarks of other third
            parties. Your use of our Services grants you no right or license to
            reproduce or otherwise use any HipHipGo or third-party trademarks.
          </p>
        </section>
        <section className={styles.contentSection} id="changes">
          <h1>Changes</h1>
          <p>
            We are constantly updating our Services, and that means sometimes we
            have to change the legal terms under which our Services are offered.
            If we make changes that are material, we will let you know by
            posting here, or by sending you an email or other communication
            before the changes take effect. The notice will designate a
            reasonable period of time after which the new terms will take
            effect. If you disagree with our changes, then you should stop using
            our Services within the designated notice period. Your continued use
            of our Services will be subject to the new terms. However, any
            dispute that arose before the changes shall be governed by the Terms
            (including the binding individual arbitration clause) that were in
            place when the dispute arose.
          </p>
        </section>
        <section className={styles.contentSection} id="termination">
          <h1>Termination</h1>
          <p>
            HipHipGo may terminate your access to all or any part of our
            Services at any time, with or without cause, with or without notice,
            effective immediately. If you wish to terminate this Agreement or
            your HipHipGo account (if you have one), you may simply discontinue
            using our Services. All provisions of this Agreement which by their
            nature should survive termination shall survive termination,
            including, without limitation, ownership provisions, warranty
            disclaimers, indemnity and limitations of liability.
          </p>
        </section>
        <section className={styles.contentSection} id="disclaimer">
          <h1>Disclaimer of Warranties</h1>
          <p>
            Our Services are provided &ldquo;as is.&rdquo; HipHipGo and its
            suppliers and licensors hereby disclaim all warranties of any kind,
            express or implied, including, without limitation, the warranties of
            merchantability, fitness for a particular purpose and
            non-infringement. Neither HipHipGo nor its suppliers and licensors,
            makes any warranty that our Services will be error free or that
            access thereto will be continuous or uninterrupted. You understand
            that you download from, or otherwise obtain content or services
            through, our Services at your own discretion and risk.
          </p>
        </section>
        <section className={styles.contentSection} id="jurisdiction">
          <h1>Jurisdiction and Applicable Law</h1>
          <p>
            Except to the extent applicable law, if any, provides otherwise,
            this Agreement, any access to or use of our Services will be
            governed by the laws of the state of California, U.S.A., excluding
            its conflict of law provisions, and the proper venue for any
            disputes arising out of or relating to any of the same will be the
            state and federal courts located in San Francisco County,
            California.
          </p>
        </section>
        <section className={styles.contentSection} id="arbitration">
          <h1>Arbitration Agreement</h1>
          <p>
            Except for claims for injunctive or equitable relief or claims
            regarding intellectual property rights (which may be brought in any
            competent court without the posting of a bond), any dispute arising
            under this Agreement shall be finally settled in accordance with the
            Comprehensive Arbitration Rules of the Judicial Arbitration and
            Mediation Service, Inc. (&ldquo;JAMS&rdquo;) by three arbitrators
            appointed in accordance with such Rules. The arbitration shall take
            place in San Francisco, California, in the English language and the
            arbitral decision may be enforced in any court. The prevailing party
            in any action or proceeding to enforce this Agreement shall be
            entitled to costs and attorneys&rsquo; fees.
          </p>
        </section>
        <section className={styles.contentSection} id="liability">
          <h1>Limitation of Liability</h1>
          <p>
            In no event will HipHipGo, or its suppliers or licensors, be liable
            with respect to any subject matter of this Agreement under any
            contract, negligence, strict liability or other legal or equitable
            theory for: (i) any special, incidental or consequential damages;
            (ii) the cost of procurement for substitute products or services;
            (iii) for interruption of use or loss or corruption of data; or (iv)
            for any amounts that exceed the fees paid by you to HipHipGo under
            this Agreement during the twelve (12) month period prior to the
            cause of action. HipHipGo shall have no liability for any failure or
            delay due to matters beyond their reasonable control. The foregoing
            shall not apply to the extent prohibited by applicable law.
          </p>
        </section>
        <section className={styles.contentSection} id="representation">
          <h1>General Representation and Warranty</h1>
          <p>You represent and warrant that your use of our Services:</p>
          <ul>
            <li>Will be in strict accordance with this Agreement;</li>
            <li>
              Will comply with all applicable laws and regulations (including
              without limitation all applicable laws regarding online conduct
              and acceptable content, the transmission of technical data
              exported from the United States or the country in which you
              reside, privacy, and data protection); and
            </li>
            <li>
              Will not infringe or misappropriate the intellectual property
              rights of any third party.
            </li>
          </ul>
        </section>
        <section className={styles.contentSection} id="sanctions">
          <h1>US Economic Sanctions</h1>
          <p>
            You expressly represent and warrant that your use of our Services
            and or associated services and products is not contrary to
            applicable U.S. Sanctions. Such use is prohibited, and HipHipGo
            reserves the right to terminate accounts or access of those in the
            event of a breach of this condition.
          </p>
        </section>
        <section className={styles.contentSection} id="indemnification">
          <h1>Indemnification</h1>
          <p>
            You agree to indemnify and hold harmless HipHipGo, its contractors,
            and its licensors, and their respective directors, officers,
            employees, and agents from and against any and all losses,
            liabilities, demands, damages, costs, claims and expenses, including
            attorneys&rsquo; fees, arising out of or related to your use of our
            Services, including but not limited to your violation of this
            Agreement, materials (such as computer software, items for sale, or
            content) that you post, and any ecommerce activities conducted
            through your or another user&rsquo;s site.
          </p>
        </section>
        <section className={styles.contentSection} id="translation">
          <h1>Translation</h1>
          <p>
            These Terms of Service were originally written in English (US). We
            may translate these terms into other languages. In the event of a
            conflict between a translated version of these Terms of Service and
            the English version, the English version will control.
          </p>
        </section>
        <section className={styles.contentSection} id="miscellaneous">
          <h1>Miscellaneous</h1>
          <p>
            This Agreement constitutes the entire agreement between HipHipGo and
            you concerning the subject matter hereof, and they may only be
            modified by a written amendment signed by an authorized executive of
            HipHipGo, or by the posting by HipHipGo of a revised version.
          </p>
          <p>
            If any part of this Agreement is held invalid or unenforceable, that
            part will be construed to reflect the parties&rsquo; original
            intent, and the remaining portions will remain in full force and
            effect. A waiver by either party of any term or condition of this
            Agreement or any breach thereof, in any one instance, will not waive
            such term or condition or any subsequent breach thereof.
          </p>
          <p>
            You may assign your rights under this Agreement to any party that
            consents to, and agrees to be bound by, its terms and conditions;
            HipHipGo may assign its rights under this Agreement without
            condition. This Agreement will be binding upon and will inure to the
            benefit of the parties, their successors and permitted assigns.
          </p>
        </section>
      </div>
    </Screen>
  );
}
