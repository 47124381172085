import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { thunkMonitor } from 'redux-thunk-monitor';
import { Map } from 'immutable';

import rootReducer from './reducer';

const INITIAL_STATE = Map();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function makeStore(initialState = INITIAL_STATE) {
  const middleware = applyMiddleware(thunkMonitor, thunk);
  const composedEnhancers = composeEnhancers(middleware);
  const store = createStore(rootReducer, initialState, composedEnhancers);

  // Enable Webpack hot module replacement for reducers
  if (module.hot)
    module.hot.accept('./reducer', () => {
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });

  return store;
}
