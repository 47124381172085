import React, { PureComponent } from 'react';

export default class CoffeeVideo extends PureComponent {
  video = undefined;

  _setVideo = ref => (this.video = ref);

  componentDidMount() {
    // We can't specify disableRemotePlayback directly on the video tag
    // because RN doesn't yet reocgnize this attribute.
    this.video.setAttribute('disableRemotePlayback', true);
  }

  render() {
    return (
      <video
        aria-hidden
        autoPlay
        controls={false}
        loop
        muted
        playsInline
        poster={require('../video/coffee-poster.jpg')}
        preload="auto"
        ref={this._setVideo}
      >
        <source src={require('../video/coffee-small.mp4')} type="video/mp4" />
      </video>
    );
  }
}
