import React, { PureComponent } from 'react';

import styles from './WeightGraphDemo.module.scss';

export default class WeightGraphDemo extends PureComponent {
  _setVideo = ref => (this.video = ref);

  componentDidMount() {
    // We can't specify disableRemotePlayback directly on the video tag
    // because RN doesn't yet reocgnize this attribute.
    this.video.setAttribute('disableRemotePlayback', true);
  }

  render() {
    return (
      <div className={styles.container} aria-hidden>
        <div>
          <img
            src={require('../video/weight-graph-poster-neutral.jpg')}
            alt=""
          />
          <img
            src={require('../video/weight-graph-poster-negative.jpg')}
            alt=""
          />
        </div>
        <video
          aria-hidden
          autoPlay
          controls={false}
          loop
          muted
          playsInline
          preload="auto"
          ref={this._setVideo}
        >
          <source
            src={require('../video/weight-graph-small.mp4')}
            type="video/mp4"
          />
        </video>
      </div>
    );
  }
}
