import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './Footer.module.scss';

export default function footer() {
  const inApp = window.location.search.match(/\bapp=1\b/);

  return (
    <footer className={cx(styles.footer, { [styles.hide]: inApp })}>
      <section>
        <h1>About</h1>
        <p>
          We&rsquo;ve built HipHipGo to help make living a healthier life
          easier. Please contact us if you have questions, comments, or are
          interested in employment opportunities.
        </p>
        <p>
          <a href="mailto:info@hiphipgo.com">
            <i className="fa fa-envelope" /> info@hiphipgo.com
          </a>
        </p>
        <p>
          <a href="#app">
            <i className="fa fa-chevron-circle-up" /> Back to top
          </a>
        </p>
        <p>&copy; HipHipGo, Inc. All rights reserved.</p>
      </section>
      <section className={styles.linkSection}>
        <div>
          <h2>Legal</h2>
          <ul>
            <li>
              <Link to={{ pathname: '/tos/' }}>Terms</Link>
            </li>
            <li>
              <Link to={{ pathname: '/privacy/', hash: '#cookies' }}>
                Cookies
              </Link>
            </li>
            <li>
              <Link to={{ pathname: '/privacy/' }}>Privacy</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2>Getting started</h2>
          <ul>
            <li>
              <Link to={{ pathname: '/', hash: '#get-started' }}>
                Install the App
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
}
