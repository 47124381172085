function bindCursorAction(action, cursorName) {
  return (...args) => action(cursorName, ...args);
}

export function bindCursorActions(actions, cursorName) {
  const isActionsFunc = typeof actions === 'function';
  const boundActions = {};

  if (isActionsFunc) return bindCursorAction(actions, cursorName);

  for (const [name, action] of Object.entries(actions)) {
    boundActions[name] = bindCursorAction(action, cursorName);
  }

  return boundActions;
}
