import APIClient from '../apiclient';
import { cursors } from '@thomasw/hhgapiclient';
import { monitorThunk } from 'redux-thunk-monitor';

import { SearchCursorActions } from './cursors';
import { BoundingBox } from './geometry';
import { getSearchResultFeatures } from './state';

let _mapResolve = undefined;
let _map = new Promise(resolve => {
  _mapResolve = resolve;
});

function getSearchCursor(config, pageHandler) {
  let searchCursor = APIClient.getGooglePlaces(config);
  searchCursor = cursors.limitPages(searchCursor, 1);

  return cursors.forEachPage(searchCursor, pageHandler);
}

export function setMap(ref) {
  _mapResolve(ref);
}

export async function getMap() {
  return await _map;
}

export async function getMapBoundingBox() {
  const map = await getMap();
  return BoundingBox(map.getBounds(), map.getCenter());
}

export async function getMapCenter() {
  const map = await getMap();
  return map.getCenter();
}

export function fitMapToMarkers() {
  return async (dispatch, getState) => {
    const bounds = new window.google.maps.LatLngBounds();
    const features = getSearchResultFeatures(getState());
    const map = await getMap();

    if (features.isEmpty()) return;

    features.map(feature =>
      bounds.extend({
        lat: feature.getIn(['geometry', 'coordinates', 1]),
        lng: feature.getIn(['geometry', 'coordinates', 0])
      })
    );

    map.fitBounds(bounds);
  };
}

export function setSearchRegion(region) {
  return {
    type: 'MAP/SET_SEARCH_REGION',
    region
  };
}

export function resetSearch() {
  return dispatch => {
    dispatch(SearchCursorActions.reset());
    dispatch({ type: 'MAP/RESET_SEARCH' });
  };
}

export function setRegion() {
  return async dispatch => {
    return dispatch({
      type: 'MAP/SET_REGION',
      region: await getMapBoundingBox()
    });
  };
}

export function storePlacePage({ results }) {
  return {
    type: 'MAP/STORE_PLACE_PAGE',
    results
  };
}

export function storePlace(place) {
  return {
    type: 'MAP/STORE_PLACE',
    place
  };
}

export function setSearchQuery(query) {
  return {
    type: 'MAP/SET_SEARCH_QUERY',
    query
  };
}

export function search(query, fitMap = true) {
  async function asyncSearch(dispatch, getState) {
    const region = await getMapBoundingBox();
    const cursor = (config, pageHandler) =>
      getSearchCursor(
        {
          search: query,
          in_bbox: region.join(','),
          ordering: 'bbox_distance',
          page_size: 100,
          ...config
        },
        pageHandler
      );

    const resetAndGetItems = SearchCursorActions.resetAndGetItems(
      cursor,
      storePlacePage
    );
    dispatch(resetSearch());
    dispatch(setSearchQuery(query));
    dispatch(setSearchRegion(region));

    window.gtag('event', query ? 'Query' : 'Explore', {
      event_category: 'Place Search',
      event_label: query,
      transport_type: 'beacon'
    });

    const pages = await resetAndGetItems(dispatch, getState);

    fitMap && dispatch(fitMapToMarkers());

    return pages;
  }

  return monitorThunk(asyncSearch, 'mapSearch');
}
