import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';

import Screen from '../components/Screen';
import NavChip from './components/NavChip';

import styles from './Legal.module.scss';

export default function PrivacyPolicy() {
  const inApp = window.location.search.match(/\bapp=1\b/);
  const chipColor = inApp ? 'primary' : 'default';
  const headerStyles = cx(styles.header, { [styles.inApp]: inApp });

  return (
    <Screen>
      <Helmet>
        <title>HipHipGo: Privacy Policy</title>
        <meta
          name="description"
          content="The HipHipGo Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, and delete your information."
        />
        <meta
          name="keywords"
          content="hiphipgo, privacy, privacy policy, legal, tos"
        />
      </Helmet>
      <section className={headerStyles}>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy is meant to help you understand what information
          we collect, why we collect it, and how you can update, manage, and
          delete your information.
        </p>

        <div className={styles.toc}>
          <NavChip hash="consent" color={chipColor}>
            Your Consent
          </NavChip>

          <NavChip hash="your_data" color={chipColor}>
            Your Data
          </NavChip>

          <NavChip hash="information_collection" color={chipColor}>
            Information Collection
          </NavChip>

          <NavChip hash="information_usage" color={chipColor}>
            Information Usage
          </NavChip>

          <NavChip hash="information_storage" color={chipColor}>
            Information Storage
          </NavChip>

          <NavChip hash="information_sharing" color={chipColor}>
            Information Sharing
          </NavChip>

          <NavChip hash="cookies" color={chipColor}>
            Cookies and Tracking
          </NavChip>

          <NavChip hash="children" color={chipColor}>
            Children
          </NavChip>

          <NavChip hash="communication" color={chipColor}>
            Communication
          </NavChip>

          <NavChip hash="access" color={chipColor}>
            Accesssing Your Information
          </NavChip>

          <NavChip hash="retention" color={chipColor}>
            Data Retention
          </NavChip>

          <NavChip hash="security" color={chipColor}>
            Security
          </NavChip>

          <NavChip hash="forums" color={chipColor}>
            Public Forums
          </NavChip>

          <NavChip hash="updates" color={chipColor}>
            Updates
          </NavChip>

          <NavChip hash="contact" color={chipColor}>
            Contacting Us
          </NavChip>
        </div>

        <p>
          Effective <strong>March 10, 2019</strong>
        </p>
      </section>
      <div className={styles.doc}>
        <section className={styles.contentSection} id="consent">
          <h1>Your Consent</h1>
          <p>
            This privacy policy (&ldquo;Policy&rdquo;) explains how personal
            information is collected, used, and disclosed by HipHipGo, Inc.,
            with respect to your use of our devices, software, applications,
            websites, APIs, products and services (the &ldquo;Services&rdquo;)
            so you can make an informed decision about using the Services
            offered by HipHipGo.
          </p>
          <p>
            This Privacy Policy constitutes a binding and enforceable legal
            contract between HipHipGo and you.
          </p>
          <p>
            You may visit and/or use the Services only if you fully agree to
            this Privacy Policy — and by accessing and/or using any of the
            Services, you signify and affirm your informed consent to this
            Privacy Policy, including to the collection and processing of your
            Personal Information as defined and explained below.
          </p>
          <p>
            Please note: you are not obligated by law to provide us with any
            information. You hereby acknowledge, warrant and agree that any
            information you do provide us is provided of your own free will and
            consent, for the purposes and uses described herein.
          </p>
        </section>
        <section className={styles.contentSection} id="your_data">
          <h1>What Information Do We Collect?</h1>
          <p>
            We collect two basic types of information with our service, namely
            personal information and aggregate information.
          </p>
          <h2>Personal Information</h2>
          <p>
            As used herein, the term &ldquo;personal information&rdquo; means
            information that specifically identifies an individual. The Personal
            Information collected by us mainly consists of contact details
            (e.g., e-mail address or phone number), billing details (name,
            physical billing address, and payment method), details regarding a
            browsing or usage session (IP address, and Geo-location), details
            regarding connected third-party accounts (such as the e-mail or
            username for a connected PayPal, Google or Facebook account),
            scanned identification documents provided to us (such as an ID card,
            driver&rsquo;s license, passport or official company registration
            documents), correspondences (including those made through or
            uploaded to our Services), and any other Personal Information
            provided to us by Visitors and/or Users through their access to
            and/or use of the Services. Our definition of personal information
            does not include &ldquo;aggregate&rdquo; information (see below).
          </p>
          <h2>Aggregate Information</h2>
          <p>
            Aggregate information is data we collect about a group or category
            of services or users from which individual user identities have been
            removed or are otherwise not apparent. For example, weight logs may
            be collected and combined with information from other uses of the
            service, but no personal information will be included in the
            resulting data. Aggregate information helps us understand trends in
            our users&rsquo; needs so that we can better consider new features
            or otherwise tailor our service. This policy in no way restricts or
            limits our collection and use of aggregate information, and we may
            share aggregate information about our users with third parties for
            various purposes, including to help us better understand and improve
            our service, and for advertising and marketing purposes.
          </p>
        </section>
        <section className={styles.contentSection} id="information_collection">
          <h1>How Do We Collect Such Information?</h1>
          <h2>Active Collection</h2>
          <p>
            Personal information may be collected in a number of ways when you
            use our Services. We may collect certain information you voluntarily
            provide to us which may contain personal information. For example,
            we may collect your name, address, email address, and other contact
            and demographic information when you register and set up an account
            or contact us by email or other means. We may also collect payment
            information if applicable. To enable certain features and
            functionality of the Services, you may choose to provide us with
            additional information, like your logs for weight, sleep, female
            health tracking, and transactions; and your location. In addition,
            from time to time we may collect demographic, contact or other
            personal information you voluntarily provide to us, such as in
            connection with your participation in surveys, sweepstakes,
            contests, games, promotional offers, and other activities on the
            Site.
          </p>
          <p>
            When registered users use the Services, our servers automatically
            recognizes any header information that is shared with our server.
            HipHipGo collects the IP address and header information, and may
            also collect the following:
          </p>
          <ul>
            <li>
              The email address of Registered Users of the Services, so that we
              and our partners may communicate with Registered Users.
            </li>
            <li>
              The email addresses of Registered Users who communicate with us
              via email, and/or make postings to our chat areas.
            </li>
            <li>
              Aggregate information on what pages Registered Users access or
              visit.
            </li>
            <li>
              Registered User-specific information on what pages Registered
              Users access or visit.
            </li>
            <li>
              Personal information may also be collected if you leave comments
              or other communications on the Site, such as on the chat areas,
              and/or send email directly to HipHipGo.
            </li>
          </ul>
          <p>
            By doing so your name and the content of your communication may be
            made public and can be read, collected, or used by other users of
            these forums, and could be used to send you unsolicited messages. We
            are not responsible for the personally identifiable information you
            choose to submit in these forums.
          </p>
          <h2>Automatically Collected Information</h2>
          <p>
            As mentioned above, we automatically receive certain types of
            information whenever you interact with us. For example, when you use
            the website, our systems may automatically collect your IP address
            and the type of operating system or browser you use. We may also
            collect information pertaining to your account activity, and
            standard access information, such as the time and date of your
            accessing the service and your usage of the service. We use such
            information for purposes such as compiling aggregated statistics
            about service usage, developing new features, and improving our
            services.
          </p>
          <p>
            We may also use cookie technology to collect information. See
            &ldquo;
            <a href="#cookies">
              Use of Cookies and Other Tracking Technologies
            </a>
            &rdquo; for details.
          </p>
        </section>
        <section className={styles.contentSection} id="information_usage">
          <h1>Why Do We Collect Such Information?</h1>
          <p>
            We collect such Non-personal and Personal Information for the
            following purposes:
          </p>
          <ul>
            <li>To provide and operate the Services;</li>
            <li>
              To further develop, customize and improve our Services, based on
              Visitors&rsquo; and Users&rsquo; common or personal preferences,
              experiences and difficulties;
            </li>
            <li>
              To provide our Users with ongoing customer assistance and
              technical support;
            </li>
            <li>
              To be able to contact our Visitors and Users with general or
              personalized service-related notices and promotional messages (see
              &ldquo;<a href="#communication">Communications from HipHipGo</a>
              &rdquo; for details);
            </li>
            <li>
              To facilitate, sponsor and offer certain contests, events and
              promotions, determine participants&rsquo; eligibility, monitor
              performance, contact winners and grant prizes and benefits;
            </li>
            <li>
              To create aggregated statistical data and other aggregated and/or
              inferred Non-personal Information, which we or our business
              partners may use to provide and improve our respective services;
            </li>
            <li>
              To enhance our data security and fraud prevention capabilities;
            </li>
            <li>and To comply with any applicable laws and regulations.</li>
          </ul>
        </section>
        <section className={styles.contentSection} id="information_storage">
          <h1>Where Do We Store Your Information?</h1>
          <p>
            HipHipGo Visitors&rsquo; and HipHipGo Users&rsquo; Personal
            Information may be maintained, processed and stored by HipHipGo and
            our authorized affiliates and service providers in the United States
            of America. HipHipGo and all US-based sub-processors comply with the
            EU–US privacy shield. We have employees in the US who have access to
            customer data.
          </p>
          <p>
            HipHipGo affiliates and service providers that store or process your
            Personal Information on HipHipGo&rsquo;s behalf are each committed
            to keep it protected and secured, in accordance with industry
            standards and regardless of any lesser legal requirements which may
            apply in their jurisdiction.
          </p>
          <h2>EU–US and Swiss–US Privacy Shield Frameworks</h2>
          <p>
            HipHipGo complies with the EU–US Privacy Shield Framework and the
            Swiss–US Privacy Shield Framework as set forth by the US Department
            of Commerce regarding the collection, use, and retention of personal
            information from the European Union and Switzerland and the United
            States, respectively. HipHipGo has certified to the Department of
            Commerce that it adheres to the Privacy Shield Principles of Notice,
            Choice, Accountability for Onward Transfer, Security, Data Integrity
            and Purpose Limitation, Access, and Recourse, Enforcement and
            Liability. If there is any conflict between the policies in this
            privacy policy and the Privacy Shield Principles, the Privacy Shield
            Principles shall govern. To learn more about the Privacy Shield
            program, and to view our certification page, please visit{' '}
            <a href="https://www.privacyshield.gov/">
              {' '}
              https://www.privacyshield.gov/
            </a>
            .
          </p>
          <p>
            In compliance with the EU–US and Swiss–US Privacy Shield Principles,
            HipHipGo commits to resolve complaints about your privacy and our
            collection or use of your personal information. European Union or
            Swiss individuals with inquiries or complaints regarding this
            privacy policy should first contact HipHipGo by email at{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>.
          </p>
          <p>
            Upon request HipHipGo will provide you with information about
            whether we hold any of your personal information. You may access,
            correct, or request deletion of your personal information by
            contacting us at{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>. We
            will respond to your request within 30 days.
          </p>
          <p>
            HipHipGo has further committed to refer unresolved privacy
            complaints under the EU–US and Swiss–US Privacy Shield Principles to
            an independent dispute resolution mechanism, the BBB EU PRIVACY
            SHIELD, operated by the Council of Better Business Bureaus. If you
            do not receive timely acknowledgment of your complaint, or if your
            complaint is not satisfactorily addressed, please visit{' '}
            <a href="http://www.bbb.org/EU-privacy-shield/for-eu-consumers/">
              http://www.bbb.org/EU-privacy-shield/for-eu-consumers/
            </a>{' '}
            for more information and to file a complaint.
          </p>
          <p>
            Please note that if your complaint is not resolved through these
            channels, under limited circumstances, a binding arbitration option
            may be available before a Privacy Shield Panel.
          </p>
          <p>
            HipHipGo may also be required to disclose an individual&rsquo;s
            personal information in response to a lawful request by public
            authorities, including to meet national security or law enforcement
            requirements.
          </p>
          <p>
            HipHipGo is subject to the investigatory and enforcement powers of
            the Federal Trade Commission (FTC).
          </p>
        </section>
        <section className={styles.contentSection} id="information_sharing">
          <h1>Sharing Personal Information with Third Parties</h1>
          <p>
            HipHipGo shares non-personal, aggregated information that cannot
            reasonably be used to identify an individual. This may include, for
            example, aggregated activity, weight, sleep, and expenditure logs.
            We disclose this information publicly to users as part of the
            Service.
          </p>
          <p>
            HipHipGo does not share your personal information except in the
            limited circumstances explained and defined below.
          </p>
          <h2>Third Party Services</h2>
          <p>
            HipHipGo has partnered with a number of selected service providers,
            whose services and solutions complement, facilitate and enhance our
            own. These include service providers, hosting and server co-location
            services, communications and content delivery networks (CDNs),
            billing and payment processing services, fraud detection and
            prevention services, web and service analytics, email distribution
            and monitoring services, session recording, performance measurement
            data optimization and marketing services, customer service
            outsourcing, content providers, and our legal and financial advisors
            (collectively, &ldquo;Third Party Service(s)&rdquo;).
          </p>
          <p>
            Such Third Party Services may receive or otherwise have access to
            our Visitors&rsquo; and Users&rsquo; Personal Information, in its
            entirety or in part — depending on each of their particular roles
            and purposes in facilitating and enhancing our Services and
            business, and may only use it for such purposes.
          </p>
          <p>
            Note that while our Services may contain links to other websites or
            services, we are not responsible for such websites&rsquo; or
            services&rsquo; privacy practices, and encourage you to be aware
            when you leave our Services and read the privacy statements of each
            and every website and service you visit. This Privacy Policy does
            not apply to such linked third-party websites and services.
          </p>
          <p>
            HipHipGo is accountable for personal data that it receives under the
            Privacy Shield and subsequently transfers to a third party as
            described in the Privacy Shield Principles. In particular, HipHipGo
            remains responsible and liable under the Privacy Shield Principles
            if third-party agents that it engages to process the personal data
            on its behalf do so in a manner inconsistent with the Principles,
            unless HipHipGo proves that it is not responsible for the event
            giving rise to the damage.
          </p>
          <h2>Law Enforcement, Legal Requests and Duties</h2>
          <p>
            HipHipGo may disclose or otherwise allow others access to your
            Personal Information pursuant to a legal request, such as a
            subpoena, legal proceedings, search warrant or court order, or in
            compliance with applicable laws, if we have a good faith belief that
            the law requires us to do so, with or without notice to you.
          </p>
          <h2>Protecting Rights and Safety</h2>
          <p>
            HipHipGo may share your Personal Information with others if we
            believe in good faith that this will help protect the rights,
            property or personal safety of HipHipGo, any of our Users, or any
            member of the general public, with or without notice to you.
          </p>
          <h2>When You Agree and/or Direct Us To Share</h2>
          <p>
            Our Services include certain Social Media features and widgets, such
            as the &ldquo;Google Sign-in&rdquo; feature. These Social Media
            Features may collect information such as your IP address or which
            page you are visiting, and may set a cookie to enable them to
            function properly. Social Media Features are either hosted by a
            third party or hosted directly on our Services. Your interactions
            with these third parties are governed by their policies and not
            ours.
          </p>
          <p>
            In addition, our Services may enable you to authorize us to share
            your Personal Information with third parties, for example, with a
            third-party application when you give it access to your account.
            Please be aware that if you choose to share any Personal Information
            via such functionality, you are in fact providing it to these third
            parties and not to us, and such interactions and sharing too are
            governed by such third parties&rsquo; policies and not ours.
          </p>
          <h2>HipHipGo Subsidiaries and Affiliated Companies</h2>
          <p>
            We may share Personal Information internally within our family of
            companies, for the purposes described in this Privacy Policy. In
            addition, should HipHipGo or any of its affiliates undergo any
            change in control, including by means of merger, acquisition or
            purchase of substantially all of its assets, your Personal
            Information may be shared with the parties involved in such event.
            If we have a good faith belief that such change in control might
            materially affect your Personal Information then stored with us, we
            will notify you via email and/or prominent notice on our Site of
            this event and certain choices you may have regarding your Personal
            Information. Sharing of Personal Information from HipHipGo
            subsidiaries and affiliated companies in the European Union and
            Switzerland to HipHipGo&rsquo;s United States subsidiary, adheres to
            the EU–US and Swiss–US Privacy Shield frameworks.
          </p>
          <p>
            For the avoidance of doubt, HipHipGo may share your Personal
            Information in manners other than as described above, pursuant to
            your explicit approval, or if we are legally obligated to do so.
            Moreover, HipHipGo may transfer, share, disclose or otherwise use
            Non-personal Information in its sole discretion and without the need
            for further approval.
          </p>
          <p>
            In the event that HipHipGo transfers your personal information to a
            third party that is acting as an agent, HipHipGo will enter into a
            written agreement with such third party requiring that the third
            party provide at least the same level of privacy protection as is
            required by the relevant principles, and also requiring that
            personal data is only processed for limited and specified purposes.
          </p>
        </section>
        <section className={styles.contentSection} id="cookies">
          <h1>Use of Cookies and Other Tracking Technologies</h1>
          <p>
            HipHipGo, together with its marketing, analytics and technology
            partners, uses certain monitoring and tracking technologies (such as
            cookies, beacons, pixels, tags and scripts). These technologies are
            used in order to maintain, provide and improve our Services on an
            ongoing basis, and in order to provide our Visitors, and our Users
            with a better experience. For example, thanks to these technologies,
            we are able to maintain and keep track of our Visitors&rsquo; and
            Users&rsquo; preferences and authenticated sessions, to better
            secure our Services, to identify technical issues, user trends and
            effectiveness of campaigns, and to monitor and improve the overall
            performance of our Services.
          </p>
          <p>
            The use of cookies by our partners and affiliates is not covered by
            our Policy. We do not have access or control over these cookies. Our
            affiliates may use session ID cookies on their websites to give you
            access to HipHipGo discounts and promotions. Our integration
            partners use cookies within HipHipGo in order for you to use the
            parts of the HipHipGo Toolset that are implemented by these
            partners.
          </p>
          <p>
            If you want to delete or block any cookies, please refer to the help
            and support area on your internet browser for instructions on how to
            locate the file or directory that stores cookies. Information on
            deleting or controlling cookies is also available at{' '}
            <a href="https://www.aboutcookies.org/">
              https://www.aboutcookies.org/
            </a>{' '}
            (note that this website is not provided by HipHipGo, and we
            therefore cannot ensure its accuracy, completeness or availability).
            Please note that deleting our cookies or disabling future cookies or
            tracking technologies may prevent you from accessing certain areas
            or features of our Services, or may otherwise adversely affect your
            user experience.
          </p>
          <h2>Third Party Analytics Services</h2>
          <p>
            Some parts of our Service use third party analytics services to help
            us create a better user experience. These companies may use cookies
            and similar technologies to collect information
          </p>
          <p>
            Google Analytics. You can opt-out of Google Analytics by visiting
            the{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">
              Google Analytics opt-out page
            </a>{' '}
            and installing the add-on for your specific browser.
          </p>
          <p>
            Performance and error tracking: We and certain Third Party Services
            may employ tracking, which enables them and us to improve our
            Services by measuring their effectiveness and performance.
          </p>
          <p>
            Behavioral Targeting/Re-Targeting: Certain Third Party Services and
            ad networks may display advertising on our service. Such parties may
            use certain tracking technologies to collect certain information
            about your activities on the Services and different third party
            Services to provide you with targeted advertising based on your
            interests and preferences. You may opt-out of receiving targeted ads
            from certain advertisers and ad networks by clicking{' '}
            <a href="https://preferences-mgr.truste.com/">here</a> (or if you
            are located in the European Union click{' '}
            <a href="https://YourChoicesOnline.eu">here</a> (provided by{' '}
            <a href="https://YourChoicesOnline.eu">YourChoicesOnline.eu</a>).
            Please note this does not opt you out of receiving any advertising.
          </p>
          <p>
            &ldquo;Do Not Track&rdquo; Signals: Please note that we do not
            change our practices in response to a &ldquo;Do Not Track&rdquo;
            signal in the HTTP header from a browser or mobile application.
          </p>
        </section>
        <section className={styles.contentSection} id="children">
          <h1>Information about Children</h1>
          <p>
            Our service is not intended to be used by children, and we do not
            knowingly collect personal information from children except in
            compliance with applicable law.
          </p>
          <p>
            Our <a href="/tos/">Terms of Service</a> bar children from using our
            services.
          </p>
        </section>
        <section className={styles.contentSection} id="communication">
          <h1>Communications from HipHipGo</h1>
          <h2>Promotional Messages</h2>
          <p>
            HipHipGo will use your information when needed to send you Service
            notifications and respond to you when you contact us. We may also
            use this information to promote new features or products we think
            you would be interested in. You may opt-out of these notifications
            at any time. You can control which types of marketing emails you
            receive and most notifications in your account settings. If you do
            not want to receive marketing and promotional emails from us in the
            future, please let us know by sending us an email to{' '}
            <a href="mailto:support@hiphipgo.com">support@hiphipgo.com</a> or
            follow the unsubscribe instructions that are included in each email
            communication.
          </p>
          <p>
            This will not prevent us from sending you operational, account
            related or other non-marketing emails. If you wish to no longer
            receive these emails please deactivate your account in your account
            settings. Alternatively, you can email our Customer Support at{' '}
            <a href="mailto:support@hiphipgo.com">support@hiphipgo.com</a>.
          </p>
          <h2>Service and Billing Messages</h2>
          <p>
            HipHipGo may also contact you with important information regarding
            our Services, or your use thereof.
          </p>
          <p>
            Opting out of promotional messages will not prevent us from sending
            you operational, account related or other non-marketing emails.If
            you wish to no longer receive these emails please deactivate your
            account in your account settings. Alternatively, you can email our
            Customer Support at{' '}
            <a href="mailto:support@hiphipgo.com">support@hiphipgo.com</a>.
          </p>
          <p>
            We may also contact you with service and billing-related messages
            and content. You will not be able to opt-out of receiving such
            messages.
          </p>
        </section>
        <section className={styles.contentSection} id="access">
          <h1>Accessing Your Personal Information</h1>
          <p>
            If you wish to access and/or request us to make corrections to the
            Personal Information that you have stored with us, or wish to
            request a list of what Personal Information (if any) pertaining to
            you we disclosed to third parties for direct marketing purposes,
            feel free to send us an email to{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>
            and we will respond within a reasonable timeframe and
            in accordance with any applicable law. Please note that you will be
            asked to verify your identity in order to ensure that an
            unauthorized person is not attempting to exploit your rights in
            order to access your data.
          </p>
          <p>
            You have the right to access your personal information. If your
            personally identifiable information changes, or if you no longer
            desire to use our service, you may update your personal information
            or delete it using your account settings. Alternatively, you can
            email our Customer Support at{' '}
            <a href="mailto:support@hiphipgo.com">support@hiphipgo.com</a>. If
            you would like your personal data permanently deleted, please email{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>.
          </p>
        </section>
        <section className={styles.contentSection} id="retention">
          <h1>Data Retention</h1>
          <p>
            We retain your Personal Information as needed to provide you with
            our Services.
          </p>
          <p>
            We may continue to retain such Personal Information even after you
            deactivate your User Account and/or cease to use any particular
            Services, as reasonably necessary to comply with our legal
            obligations, to resolve disputes regarding our Users, prevent fraud
            and abuse, enforce our agreements and/or protect our legitimate
            interests. For permanent deletion of your data please email{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>.
          </p>
        </section>
        <section className={styles.contentSection} id="security">
          <h1>Security</h1>
          <p>
            HipHipGo has implemented security measures designed to protect the
            Personal Information you share with us, including physical,
            electronic and procedural measures. Among other things, we offer
            HTTPS secure access to all areas on our Services; the transmission
            of sensitive payment information (such as a credit card number)
            through our designated purchase forms is protected by an industry
            standard SSL/TLS encrypted connection; and we regularly maintain a
            PCI DSS (Payment Card Industry Data Security Standards)
            certification. We also regularly monitor our systems for possible
            vulnerabilities and attacks, and regularly seek new ways and Third
            Party Services for further enhancing the security of our Services
            and protection of our Visitors&rsquo; and Users&rsquo; privacy.
          </p>
          <p>
            However — regardless of the measures and efforts taken by HipHipGo,
            we cannot and do not guarantee the absolute protection and security
            of your Personal Information, or any other User Content you upload,
            publish or otherwise share with HipHipGo or anyone else.
          </p>
          <p>
            We therefore encourage you to set strong passwords for your User
            Account, and avoid providing us with any sensitive information which
            you believe its disclosure could cause you substantial or
            irreparable harm. Furthermore, email and instant messaging are not
            recognized as secure forms of communications, we request and
            encourage you not to share any Personal Information on any of these
            areas or via any of these methods.
          </p>
          <p>
            We use reasonable efforts to prevent unauthorized release of or
            access to your personal data. However, we cannot guarantee that your
            information will not be disclosed or accessed by accidental
            circumstances or by the unauthorized acts of others. If HipHipGo
            learns of a security systems breach, then we may attempt to notify
            you electronically so that you can take appropriate protective
            steps. HipHipGo may also post a notice on the Site if a security
            breach occurs. Depending on where you live, you may have a legal
            right to receive notice of a security breach in writing. To receive
            a free written notice of a security breach, you should notify us at{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>.
          </p>
          <p>
            The security of your personal information is important to us. When
            you enter sensitive information (such as credit card number and/or
            social security number, national ID, personal health information) on
            our registration or order forms, we encrypt that information using
            secure socket layer technology (SSL). Credit card details are stored
            in our Payment Gateway&rsquo;s highly secure Payment Vault that is
            fully compliant with the Payment Card Industry&rsquo;s Data Security
            Standards.
          </p>
          <p>
            If you have any questions regarding the security of our Services,
            you are more than welcome to contact us at{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>.
          </p>
        </section>
        <section className={styles.contentSection} id="forums">
          <h1>Public Forums and User Content</h1>
          <p>
            Our Services may offer publicly accessible communities and support
            forums. Be aware that any information you provide in any such areas
            may be read, collected, and used by others who access them. To
            request removal of your Personal Information from our communities or
            forums, feel free to contact us at:{' '}
            <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>. In
            some cases, we may not be able to remove your Personal Information
            from such areas. For example, if you use a third party application
            to post a comment (e.g., the Facebook social plugin application)
            while logged in to your related profile with such third party, you
            will need to login into such application or contact its provider if
            you want to have the Personal Information you posted through it
            removed.
          </p>
          <p>
            In any event, we recommend that you do not post any information (or
            use any posting means to post information) you would not want to be
            made public on these areas.
          </p>
          <p>
            If you upload any User Content to your User Account and provide it
            in any other way as part of the use of any Service, you do so at
            your own risk.
          </p>
          <p>
            We cannot control the actions of other Users or members of the
            public who may access your User Content, and are not responsible for
            the circumvention of any privacy settings or security measures you
            or we may have placed on your User Account. You understand and
            acknowledge that, even after its removal by you or us, copies of
            User Content may remain viewable in cached and archived pages or if
            any third parties have copied or stored such User Content. Again, we
            recommend that you do not upload or post any information you would
            not want to be publicly available.
          </p>
        </section>
        <section className={styles.contentSection} id="updates">
          <h1>Terms of Service, Updates and Interpretation</h1>
          <p>
            Your use of any of our Services, and any disputes arising from it,
            is subject to this Privacy Policy as well as our{' '}
            <a href="/tos/">Terms of Service</a> and all of its general
            provisions including disclaimer of warranty, limitation of liability
            and indemnity.
          </p>
          <p>
            This Privacy Policy, its interpretation, and any claims and disputes
            related hereto, shall be governed by, construed under and enforced
            in all respects solely and exclusively in accordance with the
            internal substantive laws of the State of California, without
            respect to its conflict of laws principles. Any and all such claims
            and disputes shall be brought in, and you hereby consent to them
            being decided exclusively by, a court of competent jurisdiction
            located in the State of California in the USA.
          </p>
          <p>
            HipHipGo may amend this Policy from time to time to remain
            consistent with the requirements of applicable legislation and the
            EU–US and Swiss–US Privacy Shield programs. We will post any revised
            policy on this Site, and we will provide email and/or other
            notifications of any substantial changes. Your use of the Site after
            the revised Policy has been posted will constitute your consent to
            such revised Policy.
          </p>
          <p>
            We can be reached via email at{' '}
            <a href="mailto:support@hiphipgo.com">support@hiphipgo.com</a>.
          </p>
          <p>
            Any heading, caption or section title contained herein is provided
            only for convenience, and in no way defines or explains any section
            or provision hereof, or legally binds any of us in any way.
          </p>
          <p>
            If a translated (non-English) version of this Privacy Policy
            conflicts in any way with its English version, the provisions of the
            English version shall prevail.
          </p>
        </section>
        <section className={styles.contentSection} id="contact">
          <h1>Contacting Us</h1>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at <a href="mailto:privacy@hiphipgo.com">privacy@hiphipgo.com</a>
            .
          </p>
        </section>
      </div>
    </Screen>
  );
}
