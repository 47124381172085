import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet-async';

import Screen from '../components/Screen';
import NavChip from './components/NavChip';

import styles from './Legal.module.scss';

export default function Retirement() {
  const inApp = window.location.search.match(/\bapp=1\b/);
  const chipColor = inApp ? 'primary' : 'default';
  const headerStyles = cx(styles.header, { [styles.inApp]: inApp });

  return (
    <Screen>
      <Helmet>
        <title>HipHipGo: Terms of Service</title>
        <meta name="description" content="HipHipGo has officially retired." />
        <meta
          name="keywords"
          content="hiphipgo, retirement, goodbye, thanks"
        />
      </Helmet>
      <section className={headerStyles}>
        <h1>HipHipGo has retired.</h1>
        <p>
          Thanks for using our service.
        </p>
        <div className={styles.toc}>
          <NavChip hash="faq" color={chipColor}>
            FAQ
          </NavChip>
          <NavChip hash="contact" color={chipColor}>
            Conatct
          </NavChip>
        </div>
      </section>
      <div className={styles.doc}>
        <section className={styles.contentSection} id="faq">
          <h2>What will happen to my data?</h2>
          <p>To protect your privacy, your data has been permanently deleted.</p>
          <p>
          HipHipGo's application, cache, and database servers as well as other
          infrastructure have been shut down. Data, backups and logs have been
          deleted. Our access to financial, health, and geolocation services
          has been revoked (preventing any further API access to user data 
          from these providers using our credentials).
          </p>
          <h2>
          What will happen to data imported from/exported to third parties
          (e.g. Google Fit)?
          </h2>
          <p>
          This data will remain unchanged. Please use the third party provider
          directly to manage the records stored by these services.
          </p>
          <h2>Can I continue using the iOS and Android HipHipGo apps?</h2>
          <p>
          No, the HipHipGo apps depend on APIs provided by our decommissioned
          server infrastructure. Existing installations will no longer work.
          </p>
          <p>
          The apps have also been removed from app stores.
          </p>
          <h2 id="contact">I have a question that isn't answered here. How can I contact you?</h2>
          <p>
          Please reach out to the HipHipGo team at <a href="mailto:info@hiphipgo.com">info@hiphipgo.com</a>.
          </p>
        </section>
      </div>
    </Screen>
  );
}
