import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import Home from './marketing/Home';
import Thanks from './marketing/Thanks';
import PrivacyPolicy from './marketing/PrivacyPolicy';
import Tos from './marketing/Tos';
import YC from './marketing/YC';
import Retirement from './marketing/Retirement';

import AppRouter from './routing/components/AppRouter';

import './styles/global.module.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00aeff',
      main: '#69e0ff',
      dark: '#007fcb',
      contrastText: '#000'
    },
    secondary: {
      light: '#ff7681',
      main: '#db4255',
      dark: '#a3002d',
      contrastText: '#000000'
    }
  }
});

export class App extends PureComponent {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <HelmetProvider>
          <Helmet>
            <title>HipHipGo</title>
            <meta
              name="description"
              content="Forecast your weight changes and find businesses that'll help you achieve your health goals. All without counting a single calorie."
            />
            <meta
              name="keywords"
              content="hiphipgo, weight forecasting, calorie counting, food budgeting, food spend tracking, weight tracking, automatic tracking, get started, app"
            />
          </Helmet>
          <MuiThemeProvider theme={theme}>
            <AppRouter>
              <Route exact path="/" component={Home} />
              <Route exact path="/thanks/" component={Thanks} />
              <Route exact path="/privacy/" component={PrivacyPolicy} />
              <Route exact path="/tos/" component={Tos} />
              <Route exact path="/retirement/" component={Retirement} />
              <Route exact path="/yc/" component={YC} />
              <Route exact path="/demo/" component={YC} />
            </AppRouter>
          </MuiThemeProvider>
        </HelmetProvider>
      </Provider>
    );
  }
}

export default App;
