import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { getPlace, getPlaceImpact } from '../state';

const neutralPin = '#00aeff';
const negativePin = '#df344a';
const positivePin = '#49cc52';

function pinSymbol(color) {
  return {
    path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
    fillColor: color,
    fillOpacity: 1,
    strokeColor: '#fff',
    strokeWeight: 2,
    strokeOpacity: 0.9,
    scale: 0.45
  };
}

export class PlaceMarker extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    place: PropTypes.object.isRequired,
    impact: PropTypes.number
  };

  static defaultProps = {
    showLabel: false
  };

  static colors = {
    undefined: '#666',
    '-1': negativePin,
    '0': neutralPin,
    '1': positivePin
  };

  renderIcon = () => pinSymbol(PlaceMarker.colors[this.props.impact]);

  render() {
    const { place, showLabel, ...otherProps } = this.props;
    const name = place.getIn(['data', 'name']);
    const coordinates = place.getIn(['location', 'coordinates']);
    const position = { lng: coordinates.get(0), lat: coordinates.get(1) };
    const label = showLabel ? name : undefined;

    return (
      <MarkerWithLabel
        labelStyle={{
          'font-weight': 'bold',
          'font-size': '125%',
          'text-shadow':
            '-1px -1px 0 #fff, ' +
            '1px -1px 0 #fff, ' +
            '-1px 1px 0 #fff, ' +
            '1px 1px 0 #fff',
          'max-width': '6rem'
        }}
        animation={window.google.maps.Animation.DROP}
        icon={this.renderIcon()}
        labelAnchor={new window.google.maps.Point(-12.5, 8)}
        position={position}
        zIndex={showLabel ? 100 : 0}
        {...otherProps}
      >
        <div style={{ zIndex: 100 }}>{label}</div>
      </MarkerWithLabel>
    );
  }
}

function stateToProps(state, props) {
  const { id } = props;
  const place = getPlace(state, id);
  const impact = getPlaceImpact(state, id);

  return {
    place,
    impact
  };
}

export default connect(stateToProps)(PlaceMarker);
