import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  region: undefined,
  places: {},
  searchRegion: undefined,
  searchQuery: undefined
});

function setRegion(state, region) {
  state = state.set('region', fromJS(region));

  return state;
}

function resetSearch(state) {
  state = state.set('searchRegion', INITIAL_STATE.get('searchRegion'));
  state = state.set('searchQuery', INITIAL_STATE.get('seaerchQuery'));

  return state;
}

function setSearchRegion(state, region) {
  return state.set('searchRegion', fromJS(region));
}

function setSearchQuery(state, query) {
  return state.set('searchQuery', query);
}

function storePlace(state, place) {
  return state.setIn(['places', place.id], fromJS(place));
}

function storePlacePage(state, results) {
  return results.reduce(storePlace, state);
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'MAP/SET_REGION':
      return setRegion(state, action.region);
    case 'MAP/RESET_SEARCH':
      return resetSearch(state);
    case 'MAP/SET_SEARCH_REGION':
      return setSearchRegion(state, action.region);
    case 'MAP/SET_SEARCH_QUERY':
      return setSearchQuery(state, action.query);
    case 'MAP/STORE_PLACE_PAGE':
      return storePlacePage(state, action.results);
    case 'MAP/STORE_PLACE':
      return storePlace(state, action.place);
    default:
      return state;
  }
}
