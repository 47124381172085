import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './BackgroundFader.module.scss';

export const neutral = '#00aeff';
export const negative = '#db4255';
export const positive = '#7ec783';

export default class BackgroundFader extends PureComponent {
  static colors = [neutral, negative, positive];
  static neutral = neutral;
  static positive = positive;
  static negative = negative;

  static propTypes = {
    color: PropTypes.string.isRequired,
    baseOverlayClassName: PropTypes.string
  };

  static defaultProps = {
    color: neutral
  };

  render() {
    const { color, baseOverlayClassName } = this.props;
    const baseOverlay = (
      <div key="base" className={cx(styles.bgOverlay, baseOverlayClassName)} />
    );

    return [
      baseOverlay,
      ...BackgroundFader.colors.map(colorOpt => (
        <div
          key={colorOpt}
          style={{
            background: colorOpt,
            opacity: colorOpt === color ? 0.8 : 0
          }}
          className={styles.bgOverlay}
        />
      ))
    ];
  }
}
