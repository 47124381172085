import React, { PureComponent } from 'react';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import supercluster from 'supercluster';
import GeoViewport from '@mapbox/geo-viewport';

import InfoCard from '../map/components/InfoCard';
import PlaceMarker from '../map/components/PlaceMarker';
import PlaceCard from '../map/components/PlaceCard';
import { getSearchResults, getSearchResultFeatures } from '../map/state';
import { setRegion, setMap, getMapBoundingBox } from '../map/actions';
import SearchBar from '../map/components/SearchBar';
import Map from '../map/components/Map';

import AppStoreSelector from './components/AppStoreSelector';
import styles from './YC.module.scss';

export class YC extends PureComponent {
  static propTypes = {
    searchResults: PropTypes.instanceOf(OrderedSet).isRequired,
    searchResultPoints: PropTypes.instanceOf(OrderedSet).isRequired,
    setRegion: PropTypes.func.isRequired
  };

  state = {
    selectedPlace: undefined,
    visibleLabels: OrderedSet()
  };

  pointIndex = new supercluster();

  componentDidMount() {
    this.buildPointIndex();
  }

  componentDidUpdate(oldProps) {
    const { searchResults } = this.props;

    // Rebuild the point index whenever the set of search results changes
    searchResults.intersect(oldProps.searchResults) !== searchResults &&
      this.buildPointIndex();
  }

  setVisibleLabels = (newLabels = OrderedSet()) => {
    const { visibleLabels } = this.state;

    visibleLabels !== newLabels && this.setState({ visibleLabels: newLabels });
  };

  getPointIndexFeatureReprId = cluster => {
    if (cluster.properties.id) return cluster.properties.id;

    return this.pointIndex.getLeaves(cluster.id, 1)[0].properties.id;
  };

  handleBoundsChanged = () => {
    this.props.setRegion();
    this.regenerateVisibleLabels();
  };

  regenerateVisibleLabels = async () => {
    if (this.props.searchResults.isEmpty()) return;

    const bbox = await getMapBoundingBox();
    const viewport = GeoViewport.viewport(bbox, [
      window.innerWidth,
      window.innerHeight
    ]);
    const clusters = this.pointIndex.getClusters(bbox, viewport.zoom);
    const labelableIds = OrderedSet(
      clusters.map(this.getPointIndexFeatureReprId)
    );

    this.setVisibleLabels(labelableIds);
  };

  buildPointIndex = () => {
    const { searchResultPoints } = this.props;
    const pointIndex = new supercluster({ radius: 75 });

    pointIndex.load(searchResultPoints.toJS());

    this.pointIndex = pointIndex;
    this.regenerateVisibleLabels();
  };

  handlePlaceClick = id => () => {
    window.gtag('event', 'Place Open', {
      event_category: 'Place Search',
      event_label: id,
      transport_type: 'beacon'
    });
    this.setState({ selectedPlace: id });
  };

  clearSelected = () => {
    const { selectedPlace } = this.state;
    selectedPlace &&
      window.gtag('event', 'Place Close', {
        event_category: 'Place Search',
        transport_type: 'beacon'
      });
    selectedPlace && this.setState({ selectedPlace: null });
  };

  renderMarker = id => {
    const { selectedPlace, visibleLabels } = this.state;
    const isOpen =
      selectedPlace === id ? !selectedPlace : visibleLabels.includes(id);

    return (
      <PlaceMarker
        id={id}
        key={id}
        onClick={this.handlePlaceClick(id)}
        showLabel={isOpen}
      />
    );
  };

  render() {
    const { searchResults } = this.props;
    const { selectedPlace } = this.state;
    const markers = searchResults.map(this.renderMarker);
    const isDriven = navigator.webdriver || false;

    return (
      <>
        <Helmet>
          <title>HipHipGo: Search Places</title>
          <meta
            name="description"
            content="Find places that mesh with your weight goals."
          />
          <meta
            name="keywords"
            content="hiphipgo, search, places, restaurants, weight forecasting, calorie counting, food budgeting, food spend tracking, weight tracking, automatic tracking, get started, app"
          />
          <meta name="robots" content="noindex" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link rel="preconnect" href="https://maps.googleapis.com" />
          <link rel="preconnect" href="https://maps.gstatic.com" />
        </Helmet>
        {!isDriven && (
          <Map
            onMapMounted={setMap}
            onBoundsChanged={this.handleBoundsChanged}
            onClick={this.clearSelected}
          >
            <SearchBar onSearchChange={this.clearSelected} />
            {markers}
            <InfoCard />
            <div className={styles.bottomContent}>
              {selectedPlace && <PlaceCard id={selectedPlace} />}
              {!selectedPlace && <AppStoreSelector />}
            </div>
          </Map>
        )}
      </>
    );
  }
}

function stateToProps(state) {
  const searchResults = getSearchResults(state);
  const searchResultPoints = getSearchResultFeatures(state);

  return { searchResults, searchResultPoints };
}

export default connect(stateToProps, { setRegion })(YC);
