import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import DeviceFrame from './DeviceFrame';

import styles from './ProximityDemo.module.scss';

export default class ProximityDemo extends PureComponent {
  static propTypes = {
    play: PropTypes.bool.isRequired,
    centerNotification: PropTypes.bool.isRequired,
    activateAnchor: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    play: false,
    centerNotification: false
  };

  state = {
    finished: false
  };

  video = undefined;

  _setVideo = ref => (this.video = ref);

  componentDidMount() {
    // We can't specify disableRemotePlayback directly on the video tag
    // because RN doesn't yet reocgnize this attribute.
    this.video.setAttribute('disableRemotePlayback', true);

    window.document.addEventListener('aos:in:proximityDemo', this.play);
  }

  componentWillUnmount() {
    window.document.removeEventListener('aos:in:proximityDemo', this.play);
  }

  componentDidUpdate(oldProps) {
    if (this.props.play && !oldProps.play) this.play();
  }

  play = () =>
    this.setState({ finished: false }, () => {
      this.video && this.video.play();
    });

  finished = () => this.setState({ finished: true });

  handleClick = () => {
    const { onClick } = this.props;

    onClick ? onClick() : this.play();
  };

  render() {
    const { activateAnchor, centerNotification } = this.props;
    const { finished } = this.state;
    const notificationStyles = cx([
      { [styles.centerNotification]: centerNotification },
      styles.notification,
      { [styles.popIn]: finished }
    ]);

    const aosActiviationProps = activateAnchor
      ? {
          'data-aos': 'noAnimation',
          'data-aos-id': 'proximityDemo',
          'dato-aos-anchor': `.${activateAnchor}`
        }
      : {};

    return (
      <div className={styles.container} aria-hidden {...aosActiviationProps}>
        <div className={notificationStyles} onClick={this.handleClick}>
          <div>
            <h1>At Sweets & Treats? </h1>
            <p>
              Customers here gain weight. Tap to see nearby places compatible
              with your weight goals.
            </p>
          </div>
          <div>
            <i className="fa fa-exclamation-triangle"></i>
          </div>
        </div>
        <DeviceFrame className={styles.device} shake={finished}>
          <video
            aria-hidden
            muted
            playsInline
            preload="auto"
            onEnded={this.finished}
            ref={this._setVideo}
          >
            <source
              src={require('../video/proximity-small.mp4')}
              type="video/mp4"
            />
          </video>
        </DeviceFrame>
      </div>
    );
  }
}
