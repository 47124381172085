import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Chip from '@material-ui/core/Chip';
import { List } from 'immutable';
import Snackbar from '@material-ui/core/Snackbar';

import {
  hasSearchResults,
  getSearchQuery,
  getSearchRegion,
  getRegion,
  isSearchLoading
} from '../state';
import { search, resetSearch } from '../actions';
import LoadingAnimation from './LoadingAnimation';

import styles from './SearchBar.module.scss';

export class SearchBar extends PureComponent {
  static propTypes = {
    search: PropTypes.func.isRequired,
    searchRegion: PropTypes.instanceOf(List),
    mapRegion: PropTypes.instanceOf(List),
    hasSearchResults: PropTypes.bool.isRequired,
    searchQuery: PropTypes.string,
    resetSearch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onSearchChange: PropTypes.func
  };

  state = {
    input: null,
    error: undefined
  };

  setSearchField = ref => (this.searchField = ref);

  handleSubmit = e => {
    e.preventDefault();
    this.executeSearch(this.searchField.value);
  };

  handleNearbyPress = () => {
    this.executeSearch();
  };

  handleClear = () => {
    const { onSearchChange } = this.props;

    if (this.searchField) this.searchField.value = null;

    this.setState({ input: null });

    onSearchChange && onSearchChange();
  };

  handleReset = () => {
    this.props.resetSearch();
    this.handleClear();
  };

  hasRegionChanged = () => {
    const { searchRegion, mapRegion } = this.props;

    if (!searchRegion || !mapRegion) return false;

    return !searchRegion.equals(mapRegion);
  };

  handleRedoSearch = () => {
    const { searchQuery } = this.props;

    this.executeSearch(searchQuery);
  };

  setInput = ({ target: { value } }) => this.setState({ input: value });

  setError = error => this.setState({ error });
  clearError = () => this.setState({ error: null });

  sayError = () => {
    this.setError('Unable to load search results. Please try again.');
  };

  announceNoResults = pages => {
    if (!pages) return;

    if (pages[0].results.length === 0) {
      this.setError('There were no results for this area.');
    }
  };

  executeSearch = async query => {
    const { onSearchChange, search } = this.props;

    onSearchChange && onSearchChange();

    try {
      const pages = await search(query);
      this.announceNoResults(pages);
    } catch (e) {
      this.sayError();
    }
  };

  render() {
    const { hasSearchResults, searchQuery, loading } = this.props;
    const { input, error } = this.state;
    const showChip = searchQuery || hasSearchResults;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <form onSubmit={this.handleSubmit}>
            <label className={styles.search}>
              <i className="fa fa-search" aria-hidden="true" />
              {!loading && !showChip && (
                <input
                  name="q"
                  ref={this.setSearchField}
                  onChange={this.setInput}
                  placeholder="Search"
                />
              )}
              {!loading && showChip && (
                <Chip
                  className={styles.chip}
                  label={searchQuery || 'Explore'}
                  onDelete={this.handleReset}
                />
              )}
              {loading && (
                <div className={styles.loader}>
                  <LoadingAnimation height="35px" width="70px" />
                </div>
              )}
            </label>
          </form>
          {!loading && !showChip && !input && (
            <button
              className={cx([styles.button, styles.exploreButton])}
              onClick={this.handleNearbyPress}
              title="Find places in this area"
            >
              <i className="fa fa-compass" aria-hidden="true" />
            </button>
          )}
          {!loading && !showChip && !!input && (
            <button
              className={cx([styles.button, styles.clearButton])}
              onClick={this.handleClear}
              title="Clear"
            >
              <i className="fa fa-close" aria-hidden="true" />
            </button>
          )}
          {!loading && showChip && this.hasRegionChanged() && (
            <button
              className={cx([styles.button, styles.searchInAreaButton])}
              onClick={this.handleRedoSearch}
            >
              Search in this area
            </button>
          )}
        </div>
        <Snackbar
          onClose={this.clearError}
          open={!!error}
          message={error}
          action={[
            <button
              key={0}
              className={cx([styles.button, styles.clearButton])}
              style={{ color: '#fff' }}
              onClick={this.clearError}
              title="Clear"
            >
              <i className="fa fa-close" aria-hidden="true" />
            </button>
          ]}
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

function stateToProps(state) {
  return {
    searchQuery: getSearchQuery(state),
    hasSearchResults: hasSearchResults(state),
    searchRegion: getSearchRegion(state),
    mapRegion: getRegion(state),
    loading: isSearchLoading(state)
  };
}

export default connect(stateToProps, {
  search,
  resetSearch
})(SearchBar);
