import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PageHeader from './PageHeader';
import Footer from './Footer';

export default class Screen extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired
  };

  render() {
    return (
      <div>
        <PageHeader />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
