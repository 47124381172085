import React, { PureComponent } from 'react';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProximityDemo from '../../marketing/components/ProximityDemo';
import FactorDemo from '../../marketing/components/FactorDemo';
import CoffeeVideo from '../../marketing/components/CoffeeVideo';
import { search } from '../actions';

import styles from './InfoCard.module.scss';

const steps = ['intro', 'data_collection', 'search'];

const BlueStepper = withStyles({
  dotActive: {
    backgroundColor: 'rgba(30, 176, 252, 0.8)'
  }
})(MobileStepper);

export class InfoCard extends PureComponent {
  static propTypes = {
    search: PropTypes.func.isRequired
  };

  state = {
    activeStep: 0,
    closed: false,
    closing: false,
    autoplay: true
  };

  componentDidMount() {
    setTimeout(this.autoAdvance, 6000);
    window.gtag('event', steps[0], {
      event_category: 'Demo Card',
      transport_type: 'beacon'
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const { activeStep } = this.state;
    const oldStep = oldState.activeStep;

    activeStep !== oldStep &&
      window.gtag('event', steps[activeStep], {
        event_category: 'Demo Card',
        transport_type: 'beacon'
      });
  }

  handleClose = () => {
    window.gtag('event', 'Exit', {
      event_category: 'Demo Card',
      transport_type: 'beacon'
    });
    this.setState({ closing: true });
    this.props.search();
    setTimeout(() => this.setState({ closed: true }), 1250);
  };

  handleIndexChange = activeStep =>
    this.setState({
      activeStep,
      autoplay: false
    });

  next = (e, callback) => {
    const { activeStep } = this.state;
    const next = activeStep + 1;
    const length = steps.length;

    if (next > length - 1) return false;

    this.setState({ activeStep: next, autoplay: false }, callback);

    return true;
  };

  back = (e, callback) => {
    const { activeStep } = this.state;
    const next = activeStep - 1;

    if (next < 0) return false;

    this.setState({ activeStep: next, autoplay: false }, callback);

    return true;
  };

  autoAdvance = () => {
    const { activeStep, autoplay } = this.state;
    const next = activeStep + 1;
    const length = steps.length;

    if (next > length - 1) return false;
    if (!autoplay) return false;

    this.setState({ activeStep: next }, () =>
      setTimeout(this.autoAdvance, 6500)
    );

    return true;
  };

  getCardContentStyle = stepName => {
    const { activeStep } = this.state;
    const isActive = steps[activeStep] === stepName;

    return cx([styles.cardContent, { [styles.activeCardContent]: isActive }]);
  };

  render() {
    const { activeStep, closed, closing } = this.state;
    const step = steps[activeStep];

    if (closed) return null;

    return (
      <div className={cx({ [styles.dismiss]: closing })}>
        <div className={styles.centerCard}>
          <div className={styles.card}>
            <SwipeableViews
              index={activeStep}
              onChangeIndex={this.handleIndexChange}
              enableMouseEvents
            >
              <div className={this.getCardContentStyle('intro')}>
                <div className={styles.header} aria-hidden>
                  <div className={styles.bg}>
                    <img src={require('./martha.jpg')} alt="" />
                  </div>
                </div>
                <div className={styles.body}>
                  <h1>This is Martha.</h1>
                  <p>Martha says, &ldquo;hiiiiiii!&rdquo;</p>
                </div>
              </div>
              <div className={this.getCardContentStyle('data_collection')}>
                <div className={styles.header} aria-hidden>
                  <div className={styles.bg}>
                    <CoffeeVideo />
                  </div>
                  <div className={styles.timelineDemo}>
                    <FactorDemo />
                  </div>
                </div>
                <div className={styles.body}>
                  <h1>Martha uses HipHipGo.</h1>
                  <p>She keeps a close eye on her weight&nbsp;forecast.</p>
                  <p>
                    It&rsquo;s based on where she goes, how she got there, and
                    what she spends.
                  </p>
                </div>
              </div>
              <div className={this.getCardContentStyle('search')}>
                <div className={styles.header} aria-hidden>
                  <div className={styles.bg}>
                    <img
                      alt=""
                      src={require('./map.jpg')}
                      style={{ opacity: 0.1 }}
                    />
                  </div>
                  <div className={styles.proximityDemo}>
                    <ProximityDemo
                      centerNotification
                      play={step === 'search'}
                      onClick={this.handleClose}
                    />
                  </div>
                </div>
                <div className={styles.body}>
                  <h1>Martha eats stuff.</h1>
                  <h2>And that&rsquo;s okay.</h2>

                  <p>
                    HipHipGo warns her when she&rsquo;s near places that
                    don&rsquo;t mesh with her weight&nbsp;goals.
                  </p>

                  <p>It also helps her find places&nbsp;that&nbsp;do.</p>
                </div>
              </div>
            </SwipeableViews>
            <div className={styles.stepper}>
              <BlueStepper
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <button
                    disabled={activeStep === steps.length - 1}
                    onClick={this.next}
                    title="Next"
                  >
                    <i className="fa fa-caret-right" aria-hidden="true" />
                  </button>
                }
                backButton={
                  <button
                    onClick={this.back}
                    title="previous"
                    disabled={activeStep === 0}
                  >
                    <i className="fa fa-caret-left" aria-hidden="true" />
                  </button>
                }
              />
            </div>
            <div className={styles.actionBar}>
              <button onClick={this.handleClose}>
                {activeStep === steps.length - 1 ? 'Search' : 'Skip'}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.overlay} onClick={this.handleClose} />
      </div>
    );
  }
}

export default connect(undefined, {
  search
})(InfoCard);
