import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './FullHeightSection.module.scss';

export default class FullHeightSection extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.any
  };

  render() {
    return (
      <div className={cx([styles.fullHeight, this.props.className])}>
        {this.props.children}
      </div>
    );
  }
}
