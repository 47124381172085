import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Ratings from 'react-ratings-declarative';

import { getPlace, getPlaceImpact } from '../state';

import styles from './PlaceCard.module.scss';

export class PlaceCard extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    place: PropTypes.object.isRequired,
    impact: PropTypes.number
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { place, impact, id, dispatch, ...divProps } = this.props;
    const name = place.getIn(['data', 'name']);
    const priceLevel = place.getIn(['data', 'price_level']);
    const rating = place.getIn(['data', 'rating']);
    const containerStyles = cx([
      styles.container,
      { [styles.positive]: impact > 0 },
      { [styles.negative]: impact < 0 },
      { [styles.neutral]: impact === 0 }
    ]);

    return (
      <div
        key={id}
        className={containerStyles}
        data-aos="fade-up"
        {...divProps}
      >
        <div className={styles.iconArea}>
          {impact === undefined && (
            <i className="fa fa-question-circle" aria-hidden="true" />
          )}
          {impact > 0 && <i className="fa fa-heart" aria-hidden="true" />}
          {impact === 0 && <i className="fa fa-star" aria-hidden="true" />}
          {impact < 0 && (
            <i className="fa fa-arrow-circle-up" aria-hidden="true" />
          )}

          {impact === undefined && <span>Insufficent Data</span>}
          {impact > 0 && <span>Good for weight&nbsp;loss</span>}
          {impact === 0 && <span>Good for weight maintenance</span>}
          {impact < 0 && <span>Good for weight&nbsp;gain</span>}
        </div>
        <div className={styles.content}>
          <h1>{name}</h1>
          <div>
            <Ratings
              rating={rating || 0}
              widgetRatedColors="#ffd25e"
              widgetDimensions="1.25em"
              widgetSpacings="0"
            >
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
            </Ratings>
            <span>
              {priceLevel > 0 && ' • '}
              {'$'.repeat(priceLevel)}
            </span>
          </div>
        </div>
        <div className={styles.background} />
      </div>
    );
  }
}

function stateToProps(state, props) {
  const { id } = props;
  const place = getPlace(state, id);
  const impact = getPlaceImpact(state, id);

  return {
    place,
    impact
  };
}

export default connect(stateToProps)(PlaceCard);
